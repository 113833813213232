import {GPT_MODELS} from "@/global/_constants";

export class GptProfileMapper {
  static mapBackendToFrontend(profileData) {
    return {
      profileName: profileData?.name,
      intro: profileData?.intro,
      system: profileData.system,
      model: profileData?.model,
      modelList: GPT_MODELS,
      temperature: profileData?.temperature,
      top_p: 1,
      frequencyPenalty: profileData.frequency_penalty,
      presencePenalty: profileData.presence_penalty,
      stop: profileData?.stop,
      searchMaxToken: profileData.search_max_token,
      completionToken: profileData.completion_token,
      visionSettings: {
        resolution: profileData.vision_settings.resolution,
        imageContextEnabled:
          profileData.vision_settings.is_image_context_enabled,
      },
      id: profileData.id,
      datasetTags: {
        includes: profileData.dataset_tags?.includes ? profileData.dataset_tags?.includes : [],
        excludes: profileData.dataset_tags?.excludes ? profileData.dataset_tags?.excludes : []
      },
      chat_history_length: profileData.chat_history_length,
      is_personalizer_only: profileData.is_personalizer_only,
      is_auto_evaluation: profileData.is_auto_evaluation
    };
  }

  static mapFrontendToBackend(profileData) {
    const dataset_tags = {}
    let includeDatasetTags = false
    if(profileData.datasetTags.includes.length > 0){
      dataset_tags["includes"] = profileData.datasetTags.includes
      includeDatasetTags = true
    }
    if(profileData.datasetTags.excludes.length > 0){
      dataset_tags["excludes"] = profileData.datasetTags.excludes
      includeDatasetTags = true
    }
    return {
      name: profileData.profileName,
      intro: profileData.intro,
      system: profileData.system,
      model: profileData.model,
      temperature: profileData.temperature ? parseFloat(profileData.temperature) : 0,
      top_p: profileData.top_p ? parseFloat(profileData.top_p) : 1,
      frequency_penalty: profileData.frequencyPenalty
        ? parseFloat(profileData.frequencyPenalty)
        : 0,
      presence_penalty: profileData.presencePenalty
        ? parseFloat(profileData.presencePenalty)
        : 0,
      stop: profileData.stop,
      search_max_token: profileData.searchMaxToken
        ? parseInt(profileData.searchMaxToken)
        : 2500,
      completion_token: profileData.completionToken
        ? parseInt(profileData.completionToken)
        : 2000,
      vision_settings: {
        resolution: profileData.visionSettings.resolution,
        is_image_context_enabled:
          profileData.visionSettings.imageContextEnabled,
      },
      id: profileData.id,
      dataset_tags: includeDatasetTags ? dataset_tags : null,
      chat_history_length: profileData.chat_history_length ? parseFloat(profileData.chat_history_length) : 3,
      is_personalizer_only: profileData.is_personalizer_only ? profileData.is_personalizer_only : false,
      is_auto_evaluation: profileData.is_auto_evaluation ? profileData.is_auto_evaluation : false,
    };
  }
}
