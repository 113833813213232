<template>
  <div>
    <v-container>
      <v-stepper v-model="step" horizontal>
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            {{ `${$vuetify.lang.t("$vuetify.project.select_file")}` }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">
            {{ `${$vuetify.lang.t("$vuetify.project.map_data")}` }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 3" step="3">
            {{ `${$vuetify.lang.t("$vuetify.project.rank_settings")}` }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 4" step="4">
            {{ `${$vuetify.lang.t("$vuetify.project.progress")}` }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-file-input
              v-model="selectedFile"
              :label="$vuetify.lang.t('$vuetify.project.select_file')"
              accept=".csv"
              outlined
              clearable
            ></v-file-input>
            <v-row>
              <v-col cols="12" md="8"> </v-col>
              <v-col cols="12" md="4" class="text-right">
                <v-btn color="primary" @click="handleFileParsing()">
                  {{ $vuetify.lang.t("$vuetify.continue_btn_txt") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <!-- Step-2 -->
          <v-stepper-content step="2">
            <v-form ref="mapper_form">
              <v-row v-for="(field, index) in fields" :key="index" cols="12" md="6">
                <!-- {{ field }} -->
                <v-col cols="2">
                  <span>{{ field.name }}</span>
                  <span v-show="mandatoryFields.includes(field.name)" style="color: red;">*</span>
                </v-col>
                <v-col cols="4">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon size="medium"> mdi-help-circle </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ `${$vuetify.lang.t("$vuetify.project.key_type")}` }} {{ field.key_type }}<br />
                      {{ `${$vuetify.lang.t("$vuetify.project.value_type")}` }}: {{ field.value_type }}<br />
                      {{ $vuetify.lang.t("$vuetify.description_label_txt") }}:
                        <div v-for="(line, index) in formatDescription(field.description, 10)" :key="index">
                            {{ line }}
                        </div>
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-if="field.key_type === 'str' || field.key_type === 'Optional[str]'"
                    v-model="field.mapped_data"
                    :items="headersFromCSV"
                    dense
                    hide-selected
                    outlined
                    :rules="[
                      (v) =>
                        validateMandatoryFields(field.name, field.mapped_data) ||
                        $vuetify.lang.t('$vuetify.project.field_required'),
                      (v) =>
                        validateNoDuplicates(field.name, field.mapped_data) ||
                        $vuetify.lang.t('$vuetify.project.already_assigned_to_another_field'),
                    ]"
                  ></v-autocomplete>
                  <v-autocomplete
                    v-else-if="field.name === 'image_url_type'"
                    v-model="field.mapped_data"
                    :items="typeSelector"
                    dense
                    hide-selected
                    outlined
                    :rules="[
                      (v) =>
                        validateMandatoryFields(field.name, field.mapped_data) ||
                        $vuetify.lang.t('$vuetify.project.field_required'),
                    ]"
                  ></v-autocomplete>

                  <template v-else-if="field.name === 'categories' || field.name === 'custom'">
                    <div>
                      <!-- {{ field }} -->
                      <div v-for="(mapped_elem, index) in field.mapped_data" :key="index" class="side-by-side">
                        <v-autocomplete
                          v-model="mapped_elem.name"
                          :items="headersFromCSV"
                          dense
                          hide-selected
                          outlined
                          :rules="[
                              (v) =>
                                  validateMandatoryFields(field.name, mapped_elem.name) ||
                                  $vuetify.lang.t('$vuetify.project.field_required'),
                              (v) => {
                                  if (field.name === 'categories') {
                                      return validateNoDuplicatesOnSameFieldMappedData(mapped_elem.name, index) ||
                                          $vuetify.lang.t('$vuetify.project.already_assigned_to_another_field');
                                  } else {
                                      return validateNoDuplicates(field.name, mapped_elem.name) ||
                                          $vuetify.lang.t('$vuetify.project.already_assigned_to_another_field');
                                  }
                              }
                          ]"
                        ></v-autocomplete>

                        <!-- Separator input box -->
                        <v-text-field
                          v-if="field.name === 'categories'"
                          v-model="mapped_elem.separator"
                          outlined
                          dense
                          label="Separator"
                        ></v-text-field>

                        <!-- Data type Drop down box -->
                        <v-autocomplete
                          v-else-if="field.name === 'custom'"
                          v-model="mapped_elem.data_type"
                          :items="['INT', 'FLOAT']"
                          outlined
                          dense
                          hide-selected
                          label="Data Type"
                        ></v-autocomplete>

                        <!-- Button to remove this category -->
                        <v-btn v-if="field.mapped_data.length > 1" @click="removeSubfield(field, index)" outlined>
                          Remove
                        </v-btn>
                      </div>

                      <!-- Button to add more categories -->
                      <v-btn @click="addSubfield(field)" outlined>
                        {{ `${$vuetify.lang.t("$vuetify.project.add_more")}` }}
                      </v-btn>
                    </div>
                  </template>

                  <v-autocomplete
                    v-else-if="field.key_type === 'List[str]'"
                    v-model="field.mapped_data"
                    :items="headersFromCSV"
                    dense
                    hide-selected
                    outlined
                    small-chips
                    multiple
                    deletable-chips
                    :rules="[
                        (v) =>
                            validateMandatoryFields(field.name, field.mapped_data) ||
                            $vuetify.lang.t('$vuetify.project.field_required'),
                        (v) =>
                            validateNoDuplicates(field.name, field.mapped_data) ||
                            $vuetify.lang.t('$vuetify.project.already_assigned_to_another_field'),
                    ]"
                  ></v-autocomplete>

                  <v-autocomplete
                    v-else-if="field.key_type === 'boolean' || field.key_type === 'Optional[boolean]'"
                    v-model="field.mapped_data"
                    :items="['true', 'false']"
                    dense
                    hide-selected
                    outlined
                  ></v-autocomplete>

                  <template v-else-if="field.name === 'search_settings'">
                    <div>
                      <v-autocomplete
                        v-model="field.mapped_data.prioritize_category"
                        :items="getSelectedCategoryForPrioritize()"
                        label="Prioritize Category"
                        dense
                        hide-selected
                        outlined
                        :rules="[
                                (v) => validatePrioritizeCategory(field.mapped_data) ||
                                $vuetify.lang.t('$vuetify.project.field_required_for_prioritize_category')
                        ]"
                      ></v-autocomplete>
                      <div>
                        <div v-if="field.mapped_data.prioritize_values.length > 0" class="d-flex flex-wrap mb-2">
                          <v-chip v-for="(item, index) in field.mapped_data.prioritize_values" :key="index"
                                  close
                                  @click:close="handleRemovePrioritizeValue(index)"
                                  small
                                  class="ma-1"
                          >
                            {{ item }}
                          </v-chip>
                        </div>
                        <div>
                          <v-text-field class="mb-0 pb-0"
                                        v-model="inputPrioritizeValue"
                                        label="Prioritize Values"
                                        dense
                                        hide-selected
                                        outlined
                                        small-chips
                                        @keyup.enter="handleAddInputPrioritizeValue"
                                        clearable
                                        :rules="[
                                          (v) => validatePrioritizeValues(field.mapped_data) ||
                                            $vuetify.lang.t('$vuetify.project.field_required_for_prioritize_values')
                                        ]"
                          ></v-text-field>

                          <v-btn class="mb-8"
                                 @click="handleAddInputPrioritizeValue"
                                 outlined
                          > Add Value </v-btn>
                        </div>
                      </div>

                      <div>
                        <div>
                          <div class="side-by-side">
                            <v-autocomplete v-model="selectPrioritizeFlag"
                                            :items="getSelectedFlagForPrioritize()"
                                            label="Prioritize Flag"
                                            dense
                                            hide-selected
                                            outlined
                                            @change="handleInputPrioritizeFlag">
                            </v-autocomplete>
                            <v-autocomplete
                              v-model="setPrioritizeFlagValue"
                              :items="['true', 'false']"
                              label="Flag Value"
                              dense
                              hide-selected
                              outlined
                              @change="handleInputPrioritizeFlag">
                            </v-autocomplete>
                          </div>
                        </div>
                        <div>
                          <div v-if="field.mapped_data.downgrade_values.length > 0"
                               class="d-flex flex-wrap mb-2">
                            <v-chip
                              v-for="(item, index) in field.mapped_data.downgrade_values"
                              :key="index" close
                              @click:close="handleRemoveDowngradeValue(index)"
                              small
                              class="ma-1">
                              {{ item }}
                            </v-chip>
                          </div>
                          <v-text-field class="mb-0 pb-0"
                                        v-model="inputDowngradeValue"
                                        label="Downgrade Values"
                                        dense
                                        hide-selected
                                        outlined
                                        small-chips
                                        @keyup.enter="handleAddInputDowngradeValue"
                                        clearable>
                          </v-text-field>

                          <v-btn class="mb-8"
                                 @click="handleAddInputDowngradeValue"
                                 outlined>
                            Add Value
                          </v-btn>
                        </div>
                      </div>

                      <div class="side-by-side">

                        <v-text-field
                          v-model="field.mapped_data.cluster_size"
                          label="Cluster Size"
                          type="number"
                          dense
                          outlined
                          :min="1"
                          :max="5000"
                          :rules="[validateMinMax(1, 5000)]"
                        ></v-text-field>

                        <v-select
                          v-model="field.mapped_data.is_keyword_enabled"
                          :items="['true', 'false']"
                          label="Is Keyword Enabled"
                          dense
                          hide-selected
                          outlined
                        ></v-select>

                        <v-text-field
                          v-model="field.mapped_data.top_k"
                          label="Top K"
                          type="number"
                          dense
                          outlined
                          :min="1"
                          :max="50"
                          :rules="[validateMinMax(1, 50)]"
                        ></v-text-field>

                      </div>

                      <!-- <v-slider v-model="field.mapped_data.top_k" :min="1" :max="10" label="Top K"
                          thumb-label="always"></v-slider> -->
                    </div>
                  </template>
                </v-col>
              </v-row>
            </v-form>
            <v-btn color="primary" class="mr-4"  :loading="false" @click="previousStep(step)">{{
                $vuetify.lang.t("$vuetify.prev_btn_txt")
              }}</v-btn>
            <v-btn color="primary" class="mr-4" :loading="false" @click="nextStep(step)">{{
                $vuetify.lang.t("$vuetify.continue_btn_txt")
              }}</v-btn>
          </v-stepper-content>

          <!-- Step-3 -->
          <v-stepper-content step="3">
            <v-form ref="rank_form">
              <v-row cols="12" md="6">
                <v-col cols="2">
                  <span>Interval</span>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="interval"
                    :items="intervalOptions"
                    dense
                    hide-selected
                    outlined
                    small-chips
                    multiple
                    deletable-chips
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row cols="12" md="6">
                <v-col cols="2">
                  <span>Split Size</span>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    type="number"
                    v-model="splitSize"
                    outlined
                    dense
                    :min="0"
                    :max="1"
                    :step="0.01"
                    decimal
                    @change="handleNonEmptyValue('splitSize')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row cols="12" md="6">
                <v-col cols="2">
                  <span>Epoch</span>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    type="number"
                    v-model="epoch"
                    outlined
                    dense
                    :min="1"
                    decimal
                    @change="handleNonEmptyValue('epoch')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-btn color="primary" class="mr-4" outlined :loading="false" @click="previousStep(step)">{{
                $vuetify.lang.t("$vuetify.prev_btn_txt")
              }}</v-btn>
            <v-btn color="primary" class="mr-4" :loading="false" @click="handleTrain()">{{
                $vuetify.lang.t("$vuetify.continue_btn_txt")
              }}</v-btn>
          </v-stepper-content>

          <!-- Step-4 -->
          <v-stepper-content step="4">
            <template>
              <!-- {{ mapper_body }} -->
              <v-container fluid>
                <v-row no-gutters>
                  <v-col v-for="(state, index) in states" :key="index" cols="12" class="list-item-col">
                    <v-list>
                      <v-list-item>
                        <!-- Circle icon with conditional green tick -->
                        <v-list-item-icon>
                          <v-icon
                            :color="
                                state.status === 'success'
                                    ? 'green'
                                    : state.status === 'failed'
                                    ? 'red'
                                    : undefined
                            "
                          >
                            {{
                              state.status === "success"
                                ? "mdi-check-circle"
                                : state.status === "failed"
                                  ? "mdi-close-circle"
                                  : "mdi-checkbox-blank-circle-outline"
                            }}
                          </v-icon>
                        </v-list-item-icon>

                        <!-- State text -->
                        <v-list-item-content>
                          <v-list-item-title>
                            {{
                              state.message === "Creating mapper"
                                ? $vuetify.lang.t("$vuetify.setup_messages.creating_mapper")
                                : state.message === "Creating Indices"
                                  ? $vuetify.lang.t("$vuetify.setup_messages.creating_indices")
                                  : state.message === "Creating Rank settings"
                                    ? $vuetify.lang.t("$vuetify.setup_messages.creating_rank_settings")
                                    : state.message === "Uploading CSV"
                                      ? $vuetify.lang.t("$vuetify.setup_messages.uploading_data")
                                      : state.message === "Error in completing the step"
                                        ? $vuetify.lang.t("$vuetify.setup_messages.error_in_completing_the_step")
                                        : state.message === "Mapper created successfully"
                                          ? $vuetify.lang.t("$vuetify.setup_messages.mapper_created_successfully")
                                          : state.message === "Mapper updated successfully"
                                            ? $vuetify.lang.t("$vuetify.setup_messages.mapper_updated_successfully")
                                            : state.message.includes("Index-Create Task finished successfully")
                                              ? $vuetify.lang.t("$vuetify.setup_messages.index_created_successfully")
                                              : state.message === "Rank settings created successfully"
                                                ? $vuetify.lang.t(
                                                  "$vuetify.setup_messages.rank_settings_created_successfully"
                                                )
                                                : state.message.includes("Csv-Upload Task finished successfully")
                                                  ? $vuetify.lang.t("$vuetify.setup_messages.data_uploaded_successfully")
                                                  : state.message === "Flag value should be boolean"
                                                    ? $vuetify.lang.t("$vuetify.setup_messages.flag_value_should_be_boolean")
                                                    : state.message
                            }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>

                <v-row no-gutters style="pointer-events: none">
                  <v-col v-for="(state, index) in states" :key="index" cols="1.5" class="progress-col">
                    <v-progress-linear
                      v-model="state.progress_value"
                      height="25"
                      color="primary"
                      background-color="#e0e0e0"
                    ></v-progress-linear>
                    <div class="vertical-line"></div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      v-if="this.setupStatus === 'done'"
                      color="primary"
                      class="mr-4"
                      :loading="false"
                      @click="setupCompleted()"
                    >{{ `${$vuetify.lang.t("$vuetify.project.finish")}` }}
                    </v-btn>
                    <v-btn
                      v-if="this.setupLoader && this.setupStatus !== 'failed'"
                      color="primary"
                      class="mr-4 secondary-disabled-button"
                    >
                      {{ `${$vuetify.lang.t("$vuetify.project.setup_in_progress")}` }}
                      <v-progress-circular indeterminate :size="20" :width="2" color="white"></v-progress-circular>
                    </v-btn>
                    <v-btn
                      v-if="this.setupStatus === 'failed'"
                      color="error"
                      class="mr-4"
                      outlined
                      :loading="false"
                      @click="tryAgain()"
                    >{{ $vuetify.lang.t("$vuetify.common.try_again") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import {
  REFRESHTOKEN,
  GET_MAPPER,
  GET_RANK_SETTINGS,
  CREATE_MAPPER,
  UPDATE_MAPPER,
  CREATE_INDICES,
  GET_INDICES,
  CREATE_RANK,
  UPDATE_RANK_SETTINGS,
  UPLOAD_RECOMMENDER_DATA,
  ITEMS_TRAIN,
  IMAGES_TRAIN,
  RANKS_TRAIN,
  GET_TASK_STATUS,
} from "@/store/_actiontypes";

import Papa from "papaparse";

import { timeToNextToken, formatNum, capitalizeFirstLetter, formatDateTime } from "@/helpers/helper";
import getEnv from "@/config/env";
import Sentry from "@/plugins/sentry";

export default {
  props: {},
  components: {},
  data() {
    return {
      formatNum,
      capitalizeFirstLetter,
      formatDateTime,
      isLoading: false,
      step: 1,
      selectedFile: null,
      inputPrioritizeValue: '',
      inputDowngradeValue: '',
      selectPrioritizeFlag: '',
      setPrioritizeFlagValue: '',
      fields: [
        {
          name: "item_id",
          mapped_data: "",
          key_type: "str",
          value_type: "str",
          description:
            "item_id is the unit of recommended items and typically should be the Unique ID of your products. choose the parameter from your data source that you want to be the unit of recommendation.",
        },
        {
          name: "parent_item_id",
          mapped_data: "",
          key_type: "str",
          value_type: "str",
          description:
            "parent_item_id is the unit of recommended items and typically should be the Unique ID of your products. choose the parameter from your data source that you want to be the unit of recommendation.",
        },
        {
          name: "title",
          mapped_data: "",
          key_type: "str",
          value_type: "str",
          description: "Represents your product or item title.",
        },
        {
          name: "second_title",
          mapped_data: "",
          key_type: "Optional[str]",
          value_type: "str",
          description: "If your item has a catch copy or headline field you can assign it here.",
        },
        {
          name: "third_title",
          mapped_data: "",
          key_type: "Optional[str]",
          value_type: "str",
          description: "If your item has a catch copy or headline field you can assign it here.",
        },
        {
          name: "fourth_title",
          mapped_data: "",
          key_type: "Optional[str]",
          value_type: "str",
          description: "If your item has a catch copy or headline field you can assign it here.",
        },
        {
          name: "availability",
          mapped_data: "",
          key_type: "str",
          value_type: "bool",
          description:
            "Represents whether your item is available or not. Data type for this key is boolean. Make sure to adjust your data accordingly.",
        },
        {
          name: "description",
          mapped_data: "",
          key_type: "Optional[str]",
          value_type: "str",
          description: "Represents your product details information.",
        },
        {
          name: "image_url",
          mapped_data: "",
          key_type: "Optional[str]",
          value_type: "Depends on your data source",
          description: "Represents image url of your data source. This is required if you want to use image recommend end point",
        },
        {
          name: "image_url_type",
          mapped_data: "STR",
          key_type: "Optional[Enum]",
          value_type: "Selected enum value",
          description:
            "Based on this our recommended will choose how to process your image url data. Available values are STR, LIST_STR, LIST_DICT, DICT This is required if you want to use image recommend end point",
        },
        {
          name: "item_url",
          mapped_data: "",
          key_type: "str",
          value_type: "str",
          description: "Represents your product or tem details page url",
        },
        {
          name: "price",
          mapped_data: "",
          key_type: "str",
          value_type: "int",
          description: "Represents your product or item price.",
        },
        {
          name: "categories",
          mapped_data: [
            {
              name: "",
              separator: "",
            },
          ],
          key_type: "List[Dict[str,str]]",
          value_type: "str",
          description: "Represents your category list. You can add multiple category here.",
        },
        {
          name: "flag",
          mapped_data: [],
          key_type: "List[str]",
          value_type: "bool",
          description:
            "Represents list of flags available in your dataset. Flag value data type is boolean. Please do not use keys which contains other types of data as flag.",
        },
        {
          name: "average_rating",
          mapped_data: "",
          key_type: "Optional[str]",
          value_type: "int",
          description: "Represents the average rating of your product.",
        },
        {
          name: "user_ratings_total",
          mapped_data: "",
          key_type: "Optional[str]",
          value_type: "int",
          description: "Represents total ratings given by the user.",
        },
        {
          name: "custom",
          mapped_data: [
            {
              name: "None is selected",
              data_type: "FLOAT",
            },
          ],
          key_type: "List[Dict[str,str]]",
          value_type: "float",
          description: "Any custom fields you want to map.",
        },
        {
          name: "item_nearby_calculation",
          mapped_data: "false",
          key_type: "Optional[boolean]",
          value_type: "bool",
          description: "Calculate the nearby items.",
        },
        {
          name: "keywords_group_by",
          mapped_data: "",
          key_type: "Optional[str]",
          value_type: "str",
          description: "The keywords to group-by.",
        },
        {
          name: "gpt_enabled",
          mapped_data: "false",
          key_type: "Optional[boolean]",
          value_type: "bool",
          description: "If you want to enable GPT.",
        },
        {
          name: "search_settings",
          mapped_data: {
            prioritize_category: "",
            prioritize_values: [],
            prioritize_flags: {},
            downgrade_values:[],
            cluster_size: 100,
            is_keyword_enabled: "true",
            top_k: 1,
          },
          key_type: "Dict[str, Any]",
          value_type: "Dict[str, Union[str, List[str], int, bool]]",
          description: "Settings for search prioritization and clustering.",
        }
      ],
      defaultFields: [
        {
          name: "item_id",
          mapped_data: "",
          key_type: "str",
          value_type: "str",
          description:
            "item_id is the unit of recommended items and typically should be the Unique ID of your products. choose the parameter from your data source that you want to be the unit of recommendation.",
        },    {
          name: "parent_item_id",
          mapped_data: "",
          key_type: "str",
          value_type: "str",
          description:
            "parent_item_id is the unit of recommended items and typically should be the Unique ID of your products. choose the parameter from your data source that you want to be the unit of recommendation.",
        },
        {
          name: "title",
          mapped_data: "",
          key_type: "str",
          value_type: "str",
          description: "Represents your product or item title.",
        },
        {
          name: "second_title",
          mapped_data: "",
          key_type: "Optional[str]",
          value_type: "str",
          description: "If your item has a catch copy or headline field you can assign it here.",
        },
        {
          name: "third_title",
          mapped_data: "",
          key_type: "Optional[str]",
          value_type: "str",
          description: "If your item has a catch copy or headline field you can assign it here.",
        },
        {
          name: "fourth_title",
          mapped_data: "",
          key_type: "Optional[str]",
          value_type: "str",
          description: "If your item has a catch copy or headline field you can assign it here.",
        },
        {
          name: "availability",
          mapped_data: "",
          key_type: "str",
          value_type: "bool",
          description:
            "Represents whether your item is available or not. Data type for this key is boolean. Make sure to adjust your data accordingly.",
        },
        {
          name: "description",
          mapped_data: "",
          key_type: "Optional[str]",
          value_type: "str",
          description: "Represents your product details information.",
        },
        {
          name: "image_url",
          mapped_data: "",
          key_type: "Optional[str]",
          value_type: "Depends on your data source",
          description: "Represents image url of your data source. This is required if you want to use image recommend end point",
        },
        {
          name: "image_url_type",
          mapped_data: "STR",
          key_type: "Optional[Enum]",
          value_type: "Selected enum value",
          description:
            "Based on this our recommended will choose how to process your image url data. Available values are STR, LIST_STR, LIST_DICT, DICT This is required if you want to use image recommend end point",
        },
        {
          name: "item_url",
          mapped_data: "",
          key_type: "str",
          value_type: "str",
          description: "Represents your product or tem details page url",
        },
        {
          name: "price",
          mapped_data: "",
          key_type: "str",
          value_type: "int",
          description: "Represents your product or item price.",
        },
        {
          name: "categories",
          mapped_data: [
            {
              name: "",
              separator: "",
            },
          ],
          key_type: "List[Dict[str,str]]",
          value_type: "str",
          description: "Represents your category list. You can add multiple category here.",
        },
        {
          name: "flag",
          mapped_data: [],
          key_type: "List[str]",
          value_type: "bool",
          description:
            "Represents list of flags available in your dataset. Flag value data type is boolean. Please do not use keys which contains other types of data as flag.",
        },
        {
          name: "average_rating",
          mapped_data: "",
          key_type: "Optional[str]",
          value_type: "int",
          description: "Represents the average rating of your product.",
        },
        {
          name: "user_ratings_total",
          mapped_data: "",
          key_type: "Optional[str]",
          value_type: "int",
          description: "Represents total ratings given by the user.",
        },
        {
          name: "custom",
          mapped_data: [
            {
              name: "None is selected",
              data_type: "FLOAT",
            },
          ],
          key_type: "List[Dict[str,str]]",
          value_type: "float",
          description: "Any custom fields you want to map.",
        },
        {
          name: "item_nearby_calculation",
          mapped_data: "false",
          key_type: "Optional[boolean]",
          value_type: "bool",
          description: "Calculate the nearby items.",
        },
        {
          name: "keywords_group_by",
          mapped_data: "",
          key_type: "Optional[str]",
          value_type: "str",
          description: "The keywords to group-by.",
        },
        {
          name: "gpt_enabled",
          mapped_data: "false",
          key_type: "Optional[boolean]",
          value_type: "bool",
          description: "If you want to enable GPT.",
        },
        {
          name: "search_settings",
          mapped_data: {
            prioritize_category: "",
            prioritize_values: [],
            prioritize_flags: {},
            downgrade_values:[],
            cluster_size: 100,
            is_keyword_enabled: "true",
            top_k: 1
          },
          key_type: "Dict[str, Any]",
          value_type: "Dict[str, Union[str, List[str], int, bool]]",
          description: "Settings for search prioritization and clustering.",
        }
      ],
      mandatoryFields: ["item_id", "title", "availability", "item_url", "price", "categories", "flag"],
      headersFromCSV: [],
      typeSelector: ["STR", "LIST_STR", "LIST_DICT", "DICT"],
      selectedHeader: null,
      gotRecommendation: false,
      states: [
        {
          name: "map_create",
          message: "Creating mapper...",
          value: false,
          progress_value: 0,
          status: "pending",
        },
        {
          name: "index_create",
          message: "Creating Indices...",
          value: false,
          progress_value: 0,
          status: "pending",
        },
        {
          name: "rank_settings",
          message: "Creating Rank settings...",
          value: false,
          progress_value: 0,
          status: "pending",
        },
        {
          name: "csv_upload",
          message: "Uploading CSV...",
          value: false,
          progress_value: 0,
          status: "pending",
        },
      ],
      file: null,
      mapper_body: {
        key_map: {},
      },
      mapperCreatedOnce: false,
      rankSettingsCreatedOnce: false,
      setupStatus: "pending", // pending | done | failed
      fieldChanged: false,
      polling: null,
      interval: ["weekly", "bi-weekly", "monthly", "quarterly", "yearly"],
      intervalOptions: ["weekly", "bi-weekly", "monthly", "quarterly", "yearly"],
      splitSize: 0.1,
      epoch: 200,
      rank_settings: {},
      setupLoader: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      selectedProject: (state) => state.project.selectedProject,
      previouslySetupState: (state) => state.project.previouslySetupState,
    }),
  },
  watch: {
    fields: {
      handler: function () {
        this.gotRecommendation = true;
        this.$refs.mapper_form.validate();
        // this.fieldChanged = true;
      },
      deep: true,
    },
    step: {
      handler: function () {
        if (this.step === 1 || this.step === 2) {
          this.states = [
            {
              name: "map_create",
              message: "Creating mapper",
              value: false,
              progress_value: 0,
              status: "pending",
            },
            {
              name: "index_create",
              message: "Creating Indices",
              value: false,
              progress_value: 0,
              status: "pending",
            },
            {
              name: "rank_settings",
              message: "Creating Rank settings",
              value: false,
              progress_value: 0,
              status: "pending",
            },
            {
              name: "csv_upload",
              message: "Uploading CSV",
              value: false,
              progress_value: 0,
              status: "pending",
            },
          ];
          this.setupStatus = "pending";
        }
      },
      deep: true,
    },
    selectedFile: {
      handler: function () {
        this.gotRecommendation = false;
      },
      deep: true,
    },
    states: {
      handler: function (newStates) {
        // newStates.forEach((state, index) => {
        //     if (state.value) {
        //         this.animateProgress(index);
        //     }
        // });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    ...mapActions("account", [REFRESHTOKEN]),
    ...mapActions("project", [
      GET_MAPPER,
      CREATE_MAPPER,
      UPDATE_MAPPER,
      CREATE_INDICES,
      GET_INDICES,
      CREATE_RANK,
      UPDATE_RANK_SETTINGS,
      GET_RANK_SETTINGS,
      UPLOAD_RECOMMENDER_DATA,
      ITEMS_TRAIN,
      IMAGES_TRAIN,
      RANKS_TRAIN,
    ]),
    ...mapActions("task", [GET_TASK_STATUS]),
    handleFileParsing() {
      if (!this.selectedFile) {
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        // Parse the CSV content using Papaparse
        Papa.parse(reader.result, {
          skipEmptyLines: true,
          worker: true,
          complete: (result) => {
            if (result.data) {
              this.headersFromCSV = ["None is selected", ...result.data[0]];
              this.step = 2;
              if (!this.gotRecommendation && !this.mapperCreatedOnce) {
                this.predictHeadersForFields();
              }
            } else {
              console.error("Error in parsing the CSV file.");
            }
          },
        });
      };

      reader.readAsText(this.selectedFile);
    },
    predictHeadersForFields() {
      this.fields = JSON.parse(JSON.stringify(this.defaultFields));
      this.fields.forEach((field) => {
        if (this.mandatoryFields.includes(field.name)) {
          // Here, we are matching the field with the column headers
          const closestMatches = this.findClosestMatches(field.name, this.headersFromCSV);

          // We are omitting the string if it's already mapped with another field,
          // in this case we are taking the very next match
          const unmappedClosest = this.findUnmappedClosest(closestMatches);
          if (field.name === "categories") {
            field.mapped_data = [
              {
                name: unmappedClosest || "None is selected",
                separator: "",
              },
            ];
          } else if (field.name === "flag") {
            field.mapped_data.push(unmappedClosest);
          } else {
            field.mapped_data = unmappedClosest || "None is selected";
          }
        } else if (
          field.name !== "image_url_type" &&
          field.name !== "custom" &&
          field.name !== "item_nearby_calculation" &&
          field.name !== "gpt_enabled" &&
          field.name !== "search_settings"
        ) {
          field.mapped_data = "None is selected";
        }
      });
    },
    findClosestMatches(target, array) {
      const matches = [];

      array.forEach((str) => {
        let distance = this.calculateLevenshteinDistance(target, str);
        if (str === "None is selected") {
          distance = Infinity;
        }
        matches.push({
          value: str,
          distance,
        });
      });

      matches.sort((a, b) => a.distance - b.distance);

      return matches.map((match) => match.value);
    },
    calculateLevenshteinDistance(a, b) {
      // Wagner–Fischer DP for to calculate Edit distance (Levenshtein-Distance)
      const dp = Array.from(Array(a.length + 1), () => Array(b.length + 1).fill(0));

      for (let i = 0; i <= a.length; i++) {
        for (let j = 0; j <= b.length; j++) {
          if (i === 0) dp[i][j] = j;
          else if (j === 0) dp[i][j] = i;
          else dp[i][j] = Math.min(dp[i - 1][j - 1] + (a[i - 1] !== b[j - 1] ? 1 : 0), dp[i][j - 1] + 1, dp[i - 1][j] + 1);
        }
      }
      return dp[a.length][b.length];
    },
    findUnmappedClosest(closestMatches) {
      for (const match of closestMatches) {
        if (!this.isMapped(match)) {
          return match;
        }
      }
      return null;
    },
    removeNoneOption(items, fieldName) {
      if (this.mandatoryFields.includes(fieldName)) {
        return items.filter((item) => item !== "None is selected");
      }
      return items;
    },
    isMapped(value) {
      let is_mapped = false;
      for (let i = 0; i < this.fields.length && !is_mapped; i++) {
        if (this.fields[i].mapped_data === value) {
          is_mapped = true;
          break;
        }
        if (this.fields[i].key_type === "List[str]") {
          for (let j = 0; j < this.fields[i].mapped_data.length; j++) {
            if (this.fields[i].mapped_data[j] === value) {
              is_mapped = true;
              break;
            }
          }
        }
        if (this.fields[i].key_type === "List[Dict[str,str]]") {
          for (let j = 0; j < this.fields[i].mapped_data.length; j++) {
            if (this.fields[i].mapped_data[j].name === value) {
              is_mapped = true;
              break;
            }
          }
        }
      }
      return is_mapped;
    },
    validatePrioritizeCategory(mappedData) {
      if ((mappedData.prioritize_values.length > 0 && !mappedData.prioritize_category) || (mappedData.prioritize_category === 'None is selected' && mappedData.prioritize_values.length !== 0) ) {
        return false;
      }
      return true;
    },
    validatePrioritizeValues(mappedData) {
      if (mappedData.prioritize_category && mappedData.prioritize_values.length === 0 && mappedData.prioritize_category !== 'None is selected') {
        return false;
      }
      return true;
    },
    validateMandatoryFields(field_name, mappedData) {
      const isMandatoryField = this.mandatoryFields.includes(field_name);

      if (field_name === "flag") {
        const isNone = mappedData.includes("None is selected") || mappedData.length === 0;

        return !isNone;
      }

      return !(isMandatoryField && (!mappedData || mappedData === "None is selected"));
    },
    validateNoDuplicatesOnSameFieldMappedData(mappedData, index) {
      const categoriesField = this.fields.find(element => element.name === "categories");
      if (categoriesField && categoriesField.mapped_data.length > 1) {
        for (let i = 0; i < categoriesField.mapped_data.length; i++) {
          if (i !== index && categoriesField.mapped_data[i].name === mappedData) {
            return false;
          }
        }
      }
      return true;
    },
    validateNoDuplicates(field_name, mappedData) {
      if (mappedData === "None is selected") {
        return true;
      }

      if (field_name === "custom") {
        let found_idx = -1;
        let found_idx_j = -1;
        for (let i = 0; i < this.fields.length; i++) {
          if (this.fields[i].name === "custom" || this.fields[i].name === "flag") {
            // Searching duplicated on other List-of-Object type fields
            if (field_name !== this.fields[i].name) {
              for (let j = 0; j < this.fields[i].mapped_data.length; j++) {
                if (mappedData === this.fields[i].mapped_data[j].name) {
                  return false;
                }
              }
            }
            // Searching duplicated on other List type fields
            if (this.fields[i].name === "flag") {
              for (let j = 0; j < this.fields[i].mapped_data.length; j++) {
                if (mappedData === this.fields[i].mapped_data[j]) {
                  return false;
                }
              }
            }
            // Searching duplicates among own fields
            for (let j = 0; j < this.fields[i].mapped_data.length; j++) {
              if (mappedData === this.fields[i].mapped_data[j].name) {
                found_idx = i;
                found_idx_j = j;
                break;
              }
            }
          } else {
            if (mappedData === this.fields[i].mapped_data) {
              found_idx = i;
              break;
            }
          }
        }

        if (found_idx === -1 && found_idx_j === -1) {
          return true;
        }

        let second_time_found = -1;
        let second_time_found_j = -1;
        if (found_idx_j !== -1) {
          found_idx -= 1;
        }
        for (let i = found_idx + 1; i < this.fields.length; i++) {
          if (this.fields[i].name === "custom") {
            if (found_idx_j !== -1) {
              for (let j = found_idx_j + 1; j < this.fields[i].mapped_data.length; j++) {
                if (mappedData === this.fields[i].mapped_data[j].name) {
                  second_time_found = i;
                  second_time_found_j = j;
                  break;
                }
              }
            } else {
              for (let j = 0; j < this.fields[i].mapped_data.length; j++) {
                if (mappedData === this.fields[i].mapped_data[j].name) {
                  second_time_found = i;
                  second_time_found_j = j;
                  break;
                }
              }
            }
          } else {
            // if (mappedData === this.fields[i].mapped_data) {
            //     second_time_found = i;
            //     break;
            // }
          }
        }
        return second_time_found === -1 && second_time_found_j === -1;
      }

      if (field_name === "flag") {
        let found_idx = -1;
        let found_idx_j = -1;
        let flag_field_idx = -1;
        for (let i = 0; i < this.fields.length; i++) {
          // Searching duplicate inside own fields
          if (this.fields[i].name === "flag") {
            flag_field_idx = i;
            for (let j = 0; j < this.fields[i].mapped_data.length - 1; j++) {
              if (mappedData[this.fields[i].mapped_data.length - 1] === this.fields[i].mapped_data[j]) {
                found_idx = i;
                found_idx_j = j;
                break;
              }
            }
          } else {
            // Finding mapped data first occurrence
            if (mappedData.includes(this.fields[i].mapped_data)) {
              found_idx = i;
              break;
            }
          }
        }
        // Searching duplicate inside List-of-Objects of fields data property
        for (let i = 0; i < this.fields.length; i++) {
          if (this.fields[i].name === "custom") {
            for (let j = 0; j < this.fields[i].mapped_data.length; j++) {
              if (mappedData[this.fields[flag_field_idx].mapped_data.length - 1] === this.fields[i].mapped_data[j].name) {
                return false;
              }
            }
          }
        }

        if (found_idx === -1 && found_idx_j === -1) {
          return true; // true --> no duplication
        }

        let second_time_found = -1;
        let second_time_found_j = -1;
        if (found_idx_j !== -1) {
          found_idx -= 1;
        }
        for (let i = found_idx + 1; i < this.fields.length; i++) {
          if (this.fields[i].name === "flag") {
            if (found_idx_j !== -1) {
              for (let j = found_idx_j + 1; j < this.fields[i].mapped_data.length - 1; j++) {
                if (mappedData[this.fields[i].mapped_data.length - 1] === this.fields[i].mapped_data[j]) {
                  second_time_found = i;
                  second_time_found_j = j;
                  break;
                }
              }
            } else {
              for (let j = 0; j < this.fields[i].mapped_data.length; j++) {
                if (mappedData.includes(this.fields[i].mapped_data[j])) {
                  second_time_found = i;
                  second_time_found_j = j;
                  break;
                }
              }
            }
          } else {
            // Duplicate exists on some other fields while modifying List type field
            if (mappedData.includes(this.fields[i].mapped_data)) {
              second_time_found = i;
              break;
            }
          }
        }
        return second_time_found === -1 && second_time_found_j === -1;
      }

      const mappedDataIndex = this.fields.findIndex((field) => field.mapped_data === mappedData);

      if (mappedDataIndex === -1) {
        return true;
      }

      // Check if there are no duplicates after the first occurrence
      const noDuplicatesAfterFirst = this.fields.slice(mappedDataIndex + 1).every((field) => {
        if (field.name === "custom") {
          return field.mapped_data.every((item) => item.name !== mappedData);
        } else if (field.name === "flag") {
          return field.mapped_data.every((item) => item !== mappedData);
        } else {
          return field.mapped_data !== mappedData;
        }
      });

      return noDuplicatesAfterFirst;
    },
    formatDescription(description, wordsPerLine) {
      const words = description.split(" ");
      const lines = [];
      let currentLine = "";

      for (const word of words) {
        if ((currentLine + " " + word).split(" ").length <= wordsPerLine) {
          currentLine += " " + word;
        } else {
          lines.push(currentLine.trim());
          currentLine = word;
        }
      }

      if (currentLine.trim() !== "") {
        lines.push(currentLine.trim());
      }

      return lines;
    },
    previousStep(step) {
      this.step = step - 1;
    },
    nextStep(step) {
      if (step === 2) {
        const isMapValid = this.$refs.mapper_form.validate();
        if (isMapValid) {
          this.step = step + 1;
        }
      } else {
        this.step = step + 1;
      }
    },
    addSubfield(field) {
      if (field.name === "custom") {
        field.mapped_data.push({
          name: "None is selected",
          data_type: "FLOAT",
        });
      } else {
        field.mapped_data.push({
          name: "",
          separator: "",
        });
      }
    },
    removeSubfield(field, index) {
      field.mapped_data.splice(index, 1);
    },
    animateProgress(index) {
      const interval = setInterval(() => {
        this.progressModels[index] += 5;
        if (this.progressModels[index] >= 100) {
          clearInterval(interval);
        }
      }, 100);
    },
    mapValues(responseObject, fields) {
      return fields.reduce((mappedData, field) => {
        const fieldName = field.name;
        const fieldValue = responseObject[fieldName];

        if (fieldValue !== undefined) {
          mappedData[fieldName] = fieldValue;
        }

        return mappedData;
      }, {});
    },
    handleTrain() {
      const isValid = this.$refs.rank_form.validate();

      if (isValid) {
        this.step = 4;

        // Making a proper mapper_body for the recommender
        this.fields.forEach((field) => {
          if (field.mapped_data && field.mapped_data !== "None is selected") {
            if (field.name === "custom" || field.name === "categories") {
              this.mapper_body.key_map = {
                ...this.mapper_body.key_map,
                [field.name]: [],
              };
              field.mapped_data.forEach((mapped) => {
                if (mapped.name !== "None is selected") {
                  this.mapper_body.key_map[field.name].push(mapped);
                }
              });
            } else if (field.name === "flag") {
              this.mapper_body.key_map = {
                ...this.mapper_body.key_map,
                [field.name]: [],
              };
              field.mapped_data.forEach((mapped) => {
                if (mapped !== "None is selected") {
                  this.mapper_body.key_map[field.name].push(mapped);
                }
              });
            } else if (field.key_type === "boolean" || field.key_type === "Optional[boolean]") {
              let booleanValue = field.mapped_data === "true";
              this.mapper_body.key_map = {
                ...this.mapper_body.key_map,
                [field.name]: booleanValue,
              };
            } else {
              this.mapper_body.key_map = {
                ...this.mapper_body.key_map,
                [field.name]: field.mapped_data,
              };
            }
          }
        });

        this.rank_settings = {
          rank_settings: {
            interval: this.interval,
            split_size: this.splitSize,
            epoch: this.epoch,
          },
        };

        if (timeToNextToken() < 300) {
          this.REFRESHTOKEN({
            refresh_token: this.user.refresh_token,
          }).then(
            (_response) => {
              this.startProcess();
            },
            error => {
              Sentry.captureException(error);
            }
          );
        } else {
          this.startProcess();
        }
      } else {
        console.error("Validation failed. Please check the form for errors.");
      }
    },
    finalStep() {
        this.setupLoader = false;
      this.setupStatus = this.states.some((state) => state.status === "failed") ? "failed" : "done";
      },
    async startProcess() {
      this.step = 4;
      console.log("Starting installation process...");
      this.setupLoader = true;

      // step 1
      let checkPreviousMapper = await this.getMapper();
      if (checkPreviousMapper) {
        const updateMapperResponse = await this.updateMapper();
        this.states[0].value = true;
        this.states[0].progress_value = 100;
        this.states[0].status = updateMapperResponse.status === 200 ? "success" : "failed";
        this.states[0].message = updateMapperResponse.data.detail.response;
      } else {
        const createMapperResponse = await this.createMapper();
        this.states[0].value = true;
        this.states[0].progress_value = 100;
        this.states[0].status = createMapperResponse.status === 200 ? "success" : "failed";
        this.states[0].message = createMapperResponse.data.detail.response;
      }
      if(this.states[0].status === "failed"){
        this.finalStep();
        return;
      }

      // step 2
      const createIndicesResponse = await this.createIndices();
      this.states[1].value = true;
      this.states[1].progress_value = 100;
      this.states[1].status = createIndicesResponse.status === 'success' ? "success" : "failed";
      this.states[1].message = createIndicesResponse.status === 'success' ? "Index-Create Task finished successfully" : createIndicesResponse.message;
      if(this.states[1].status === "failed"){
        this.finalStep();
        return;
      }

      // step 3
      const createRankSettingsResponse = await this.createRankSettings();
      this.states[2].value = true;
      this.states[2].progress_value = 100;
      this.states[2].status = createRankSettingsResponse.status === 200 ? "success" : "failed";
      this.states[2].message = createRankSettingsResponse.data.detail.response;
      if(this.states[2].status === "failed"){
        this.finalStep();
        return;
      }

      // step 4
      if (this.selectedFile) {
        const dataUploadResponse = await this.dataUpload();
        this.states[3].value = true;
        this.states[3].progress_value = 100;
        this.states[3].status = dataUploadResponse.status === 'success' ? "success" : "failed";
        this.states[3].message = dataUploadResponse.status === 'success' ? "Csv-Upload Task finished successfully" : dataUploadResponse.message;
      }
      if(this.states[3].status === "failed"){
        this.finalStep();
        return;
      }
      // step 5
      this.finalStep();
      if (this.setupStatus === "done") {
        this.setupCompleted();
      }

    },

    async getMapper() {
      let project_id = this.selectedProject.id;
      let response = await this.GET_MAPPER({
        project_id: project_id
      });
      return response.data.detail.response;
    },

    async createMapper() {
      let project_id = this.selectedProject.id;
      let response = await this.CREATE_MAPPER({
        project_id: project_id,
        mapper_body: this.mapper_body
      });
      return response;
    },

    async updateMapper() {
      let project_id = this.selectedProject.id;
      let response = await this.UPDATE_MAPPER({
        project_id: project_id,
        mapper_body: this.mapper_body
      });
      return response;
    },

    async createIndices() {
      let project_id = this.selectedProject.id;
      let response = await this.CREATE_INDICES({
        project_id: project_id
      });
      if (response.status === 200) {
        let task_id = response.data.detail.task_id;
        return new Promise((resolve, reject) => {
          let polling = setInterval(() => {
            this.GET_TASK_STATUS({
              project_id: project_id,
              task_id: task_id
            }).then(
              (response) => {
                if (response.data.detail.response.status !== "pending") {
                  clearInterval(polling);
                  resolve(response.data.detail.response);
                }
              },
              (error) => {
                clearInterval(polling);
                Sentry.captureException(error);
                reject(error);
              }
            );
          }, getEnv("VUE_APP_POLLING_INTERVAL"));
        });
      }
      return {
        status: 'failed',
        message: response.data.detail.response
      };
    },

    async createRankSettings() {
      let project_id = this.selectedProject.id;
      let response = await this.CREATE_RANK({
        project_id: project_id,
        settings: this.rank_settings
      });
      return response;
    },

    async dataUpload() {
      let project_id = this.selectedProject.id;
      const formData = new FormData();
      formData.append("file", this.selectedFile);
      let response = await this.UPLOAD_RECOMMENDER_DATA({
        project_id: project_id,
        file: formData,
      });
      if (response.status === 202) {
        let task_id = response.data.detail.response;
        return new Promise((resolve, reject) => {
          let polling = setInterval(() => {
            this.GET_TASK_STATUS({
              project_id: project_id,
              task_id: task_id
            }).then(
              (response) => {
                if (response.data.detail.response.status !== "pending") {
                  clearInterval(polling);
                  resolve(response.data.detail.response);
                }
              },
              (error) => {
                clearInterval(polling);
                Sentry.captureException(error);
                reject(error);
              }
            );
          }, getEnv("VUE_APP_POLLING_INTERVAL"));
        });
      }
      return {
        status: 'failed',
        message: response.data.detail.response
      };
    },
    tryAgain() {
      this.step = 1;
    },
    setupCompleted() {
      this.$emit("setupCompleted");
    },
    handleNonEmptyValue(fieldName) {
      if (this[fieldName] === "") {
        this[fieldName] = 0;
      }
    },
    getSelectedCategoryForPrioritize() {
      // Find the field object with name "categories"
      const categoriesField = this.fields.find(field => field.name === "categories");

      // Extract category names
      if (categoriesField && categoriesField.mapped_data) {
        return categoriesField.mapped_data.map(category => category.name);
      } else {
        return [];
      }
    },
    getSelectedFlagForPrioritize() {
      // Find the field object with name "flag"
      const flagsField = this.fields.find(field => field.name === "flag");

      // Extract category names
      if (flagsField && flagsField.mapped_data) {
        return flagsField.mapped_data.map(flag => flag);
      } else {
        return [];
      }
    },
    handleInputPrioritizeFlag() {
      const searchSettingsField = this.fields.find(field => field.name === 'search_settings');
      if (searchSettingsField) {
        this.$set(searchSettingsField.mapped_data, 'prioritize_flags', {}); // Clear the existing properties in prioritize_flags

        if (this.selectPrioritizeFlag && this.setPrioritizeFlagValue !== "") {
          this.$set(searchSettingsField.mapped_data.prioritize_flags, this.selectPrioritizeFlag, this.setPrioritizeFlagValue);
        }
      }
    },
    handleAddInputPrioritizeValue() {
      if (this.inputPrioritizeValue.trim() !== '') {
        const searchSettingsField = this.fields.find(field => field.name === 'search_settings');
        if (searchSettingsField) {
          searchSettingsField.mapped_data.prioritize_values.push(this.inputPrioritizeValue);
        }
        this.inputPrioritizeValue = '';
      }
    },
    handleAddInputDowngradeValue(){
      if (this.inputDowngradeValue.trim() !== '') {
        const searchSettingsField = this.fields.find(field => field.name === 'search_settings');
        if (searchSettingsField) {
          searchSettingsField.mapped_data.downgrade_values.push(this.inputDowngradeValue);
        }
        this.inputDowngradeValue = '';
      }
    },
    handleRemovePrioritizeValue(index) {
      const searchSettingsField = this.fields.find(field => field.name === 'search_settings');
      if (searchSettingsField) {
        searchSettingsField.mapped_data.prioritize_values.splice(index, 1);
      }
    },
    handleRemoveDowngradeValue(index) {
      const searchSettingsField = this.fields.find(field => field.name === 'search_settings');
      if (searchSettingsField) {
        searchSettingsField.mapped_data.downgrade_values.splice(index, 1);
      }
    },
    validateMinMax(min, max) {
      return value => {
        if (value === '' || value === null) {
          return true;
        }
        if (value < min || value > max) {
          return `Value must be between ${min} and ${max}`;
        }
        return true;
      };
    },
  },
};
</script>

<style scoped>
.tick-line {
  height: 2px;
  width: 100%;
  background-color: grey;
}

.green-line {
  background-color: green;
}

.side-by-side {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 10px;
}

.progress-col {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.vertical-line {
  height: 100%;
  width: 2px;
  background-color: whitesmoke;
}

.secondary-disabled-button {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
