<template>
  <div>
    <template>
      <div>
        <!-- Date filter -->
        <v-row>
          <v-col cols="12" xs="12" md="4">
            <v-menu
              ref="menuDates"
              v-model="menuDates"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  :placeholder="
                    $vuetify.lang.t('$vuetify.daterange_placeholder_txt')
                  "
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                color="primary"
                no-title
                scrollable
                range
                :max="dateOfToday()"
              >
                <v-spacer></v-spacer>
                <v-btn text color="error" @click="menuDates = false">
                  {{ $vuetify.lang.t("$vuetify.cancel_btn_txt") }}
                </v-btn>

                <v-btn text color="primary" @click="setDates">
                  {{ $vuetify.lang.t("$vuetify.OK_btn_txt") }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col xs="6" md="6" sm="6">
            <div class="px-2 primary--text font-weight-bold">{{ $vuetify.lang.t("$vuetify.catalog.catalog_items") }}</div>
          </v-col>
          <!-- delete all items -->
          <v-col md="6">
            <v-btn
              class="text-capitalize font-weight-bold float-right"
              color="#ed5e68"
              @click="handleDeleteClick"
            >
              <span class="white--text"
              >{{ $vuetify.lang.t("$vuetify.tooltip.delete") }} ({{
                  selectedDocuments.length || "ALL"
                }})</span
              >
              <v-icon right class="ml-2 white--text">delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- search and price range field start -->
        <v-row class="d-flex flex-column flex-md-row pa-4 mb-n6">
          <v-col width class="pa-0 pr-0 pr-md-8">
            <v-text-field
              v-model="searchText"
              :label="$vuetify.lang.t('$vuetify.insights.search')"
              outlined
              dense
              append-icon="mdi-magnify"
              @input="fetchCatalogItems"
            ></v-text-field>
          </v-col>
          <v-col class="pa-0 mt-n7 pr-0 pr-md-1 d-flex align-center">
            <!-- <span class="pl-3 pa-4 ">Price Range: </span> -->
            <v-range-slider
              v-model="priceRange"
              :max="maxPrice"
              :min="minPrice"
              :step="10"
              hide-details
              class="align-center"
              thumb-size="28"
              thumb-label="always"
              @change="updateCatalog"

            >
              <template v-slot:prepend>
                <v-text-field
                  v-model.number="priceRange[0]"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  style="width: 60px"
                  readonly
                ></v-text-field>
              </template>
              <template v-slot:append>
                <v-text-field
                  v-model.number="priceRange[1]"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  style="width: 60px"
                  readonly
                ></v-text-field>
              </template>
            </v-range-slider>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- data tables for catalog items -->
        <Loader v-if="isLoading" class="mr-2 mt-6" />
        <v-data-table
          v-else
          :headers="catalogItemHeaders"
          :loading="loading"
          :items="catalogTableItems"
          :items-per-page="itemsPerPage"
          :page.sync="page"
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :must-sort="true"
          show-select
          hide-default-footer
          @update:sort-desc="updateSort"
          v-model="selectedDocuments"
        >
          <template v-slot:[`item.item_id`]="{ item }">
            <span>{{ item.item[mapper?.item_id] }}</span>
          </template>
          <template v-slot:[`item.title`]="{ item }">
            <span>{{ item.item[mapper?.title] }}</span>
          </template>
          <template v-slot:[`item.availability`]="{ item }">
            <span>{{ item.item[mapper?.availability] }}</span>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <span>{{ item.item[mapper?.price] }}</span>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            <span>{{ formatDateTime(item.date) }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <ButtonGroup
              :groupType="ACTION_BUTTON_GROUP"
              :addButton="false"
              @onDetailsClick="detail_modal(item)"
              @onEditClick="updateItem(item)"
              :stopButton="false"
              @onDeleteClick="deleteItem(item)"
              :startButton="false"
            />
          </template>
        </v-data-table>
        <v-row>
          <v-col class="py-0" cols="12" md="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" xs="12" sm="3" md="3"></v-col>
          <v-col cols="12" xs="12" sm="6" md="6">
            <v-pagination
              class="text-center"
              v-model="page"
              :length="pageCount"
            ></v-pagination>
          </v-col>
          <v-col cols="12" offset-sm="1" offset-md="1" xs="3" sm="2" md="2">
            <v-select
              v-model="itemsPerPage"
              :items="rows"
              hide-details="auto"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <!-- edit Dialog -->
        <v-dialog v-model="openEditModal" max-width="800">
          <v-card class="d-flex flex-column">
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <h1
                              class="text-h6 text-sm-h5 text-md-h4 text-lg-h5"
                            >
                              {{ $vuetify.lang.t("$vuetify.catalog.edit_catalog_items") }}
                            </h1>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item
                        v-for="(value, key) in selectedItem.item"
                        :key="key"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="d-flex flex-wrap">
                            <v-textarea
                              v-model="selectedItem.item[key]"
                              :label="formatKey(key)"
                              outlined
                              dense
                              hide-details
                              max-rows="8"
                              auto-grow
                              :rows="dynamicRowCount"
                              clearable
                              clear-icon="mdi-close-circle"
                              color="primary"
                              class="mt-2"
                            ></v-textarea>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-end">
              <v-btn color="error" text @click="close">{{ $vuetify.lang.t('$vuetify.common.cancel') }}</v-btn>
              <v-btn color="primary" text @click="saveUpdatedItem">{{ $vuetify.lang.t('$vuetify.common.save') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Detail Dialog -->
        <v-dialog v-model="openDetailModal" max-width="800">
          <v-card
            class="d-flex flex-column"
            style="overflow-y: scroll; overflow-x: hidden"
          >
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-list>
                      <v-list-item v-for="(value, key) in modalContent.item" :key="key">
                        <v-list-item-content>
                          <v-list-item-title class="d-flex flex-wrap align-center">
                            <strong
                              class="mr-2 text-subtitle-1 text-sm-body-1 text-md-body-2 text-lg-subtitle-1 font-weight-bold"
                            >
                              {{ formatKey(key) }}:
                            </strong>
                            <div class="d-flex flex-column flex-grow-1">
                              <template v-if="imageKeys.includes(key)">
                                <v-img
                                  :src="value"
                                  class="mt-2"
                                  max-width="300"
                                  max-height="200"
                                  contain
                                ></v-img>
                              </template>
                              <template v-else-if="textKeys.includes(key)">
                              <span
                                v-html="value"
                                class="response-details-text text-wrap text-subtitle-1 word-break mt-1"
                              ></span>
                              </template>
                              <template v-else>
                              <span
                                class="response-details-text text-wrap text-subtitle-1 word-break"
                              > {{ Array.isArray(value) ? value.join(", ") : (typeof value === "object" && value !==
                                null) ? Object.values(value).join(", ") : value }}</span>
                              </template>
                            </div>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-end">
              <v-btn color="primary" text @click="close">{{ $vuetify.lang.t('$vuetify.common.close') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <DialogModal
      v-model="deleteDialog"
      :title="$vuetify.lang.t('$vuetify.confirm_alert_title_txt')"
      :message="delete_alert_message"
      :confirmButtonText="$vuetify.lang.t('$vuetify.confirm_btn_txt')"
      :cancelButtonText="$vuetify.lang.t('$vuetify.cancel_btn_txt')"
      @onConfirmation="removeItems()"
      @onCancel="deleteDialog = false"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters
} from "vuex";

import {
  GET_TASK_STATUS,
  GET_CATALOG_ITEMS,
  REMOVE_CATALOG_ITEMS,
  UPDATE_CATALOG_ITEM,
  LOAD_ALL_PROJECT,
  ADD_ALERT,
} from "@/store/_actiontypes";
import {
  ACTION_BUTTON_GROUP
} from "@/components/common/buttongroup/_buttongrouptypes";
import ButtonGroup from "@/components/common/buttongroup/ButtonGroup";
import DialogModal from "@/components/common/DialogModal";
import Loader from "@/components/common/loader/Loader";
import {IMAGE_KEYS, TEXT_KEYS} from "@/global/_constants";
import {
  timeToNextToken,
  formatDateTime,
  dateOfToday,
} from "@/helpers/helper";
export default {
  props: {
    props: {
      selectedDocuments: {
        type: Array,
        default: () => [],
      },
    },
  },
  components: {
    ButtonGroup,
    DialogModal,
    Loader,
  },

  data() {
    return {
      formatDateTime,
      ACTION_BUTTON_GROUP: ACTION_BUTTON_GROUP,
      catalogItemHeaders: [
        {
          text: this.$vuetify.lang.t("$vuetify.catalog.item_id"),
          align: "start",
          sortable: false,
          value: "item_id",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.catalog.title"),
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.catalog.availability"),
          align: "start",
          sortable: false,
          value: "availability",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.catalog.price"),
          align: "start",
          sortable: true,
          value: "price",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history_date"),
          align: "start",
          sortable: true,
          value: "date",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.catalog.actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      intervalId: null,
      itemsPerPage: 10,
      currentPage: 1,
      openEditModal: false,
      openDetailModal: false,
      toBeDeletedProjectId: null,
      deleteDialog: false,
      catalogTableItems: [],
      selectedDocuments: [],
      menuDates: false,
      dateOfToday,
      selectedItem: {},
      minPriceValue: 0,
      priceRange: [0, 0],
      maxPrice: 0,
      minPrice: 0,
      sortBy: '',
      sortDesc: true,
      // maxPrice: 0,
      // minPrice: 0,
      dates: [],
      rows: [5, 10, 20],
      returnedHits: 0,
      dynamicRowCount: 1,
      page: 1,
      search: "",
      isLoading: false,
      searchText: null,
      start_date: null,
      end_date: null,
      imageKeys:IMAGE_KEYS,
      textKeys: TEXT_KEYS,
      modalContent: {
        item: {}
      },
      timeToNextToken,
      delete_alert_message: this.$vuetify.lang.t('$vuetify.gpt_dataset.confirm_alert_msg_txt_3'),
      editedItem: {
        item: {}
      },
      itemId: null,
      updateDialog: false,
      polling: null,
      inProgress: false,
      updateResult: "",
      updateStatus: "",
      updateStartedAt: "",
    };
  },

  computed: {
    ...mapState({
      selectedProject: (state) => state.project.selectedProject,
      dateRangeText: function () {
        if (this.dates.length === 2) {
          return this.dates.join(" ~ ");
        } else {
          return "Select Date Range";
        }
      },
      catalogItems: (state) => {
        return state.project.catalogItems
      },
      pageCount(state) {

        if (state.project ?.catalogItems ?.detail ?.response ?.total_hits) {
          const count = Math.ceil(
            state.project ?.catalogItems ?.detail ?.response ?.total_hits / this.itemsPerPage
          );
          return count;
        } else return 1;
      },

    }),
    ...mapGetters("loader", ["loading"]),
    ...mapGetters("project", ["mapper"]),
  },
  watch: {
    itemsPerPage: {
      handler(value) {
        const returnedHits = this.catalogItems ?.detail ?.response ?.returned_hits ??0;
        const start = (this.page - 1) * returnedHits;
        const size = this.itemsPerPage;
        const start_date = this.startDate;
        const end_date = this.endDate;
        this.updateCatalog();
      },
      immediate: false,
    },
    page: {
      handler(value) {
        const start = (this.page - 1) * this.catalogItems ?.detail ?.response ?.returned_hits;
        const size = this.itemsPerPage;
        const start_date = this.startDate;
        const end_date = this.endDate;
        this.updateCatalog();
      },
      immediate: false,
    },

    dates() {
      this.start_date = this.dates[0];
      this.end_date = this.dates[1];
    },

  },

  mounted() {
    if (timeToNextToken() < 300) {
      this.REFRESHTOKEN({
        refresh_token: this.user.refresh_token,
      }).then(
        (response) => {

          this.getCatalogItems();
        },
        (error) => error
      );
    } else {}
    this.fetchCatalogItems();
  },
  beforeCreate() {},
  created() {
    this.fetchCatalogItems();
  },

  methods: {
    ...mapActions("alert", [ADD_ALERT]),
    ...mapActions("project", [
      GET_TASK_STATUS,
      GET_CATALOG_ITEMS,
      REMOVE_CATALOG_ITEMS,
      UPDATE_CATALOG_ITEM,
      LOAD_ALL_PROJECT,
    ]),
    detail_modal(data) {
      this.modalContent = data;
      this.openDetailModal = true;
    },

    close() {
      this.openEditModal = false;
      this.openDetailModal = false;
    },
    startInterval(task_id) {
      this.intervalId = setInterval(() => {
        this.GET_TASK_STATUS({
          project_id: this.$route.params.project_id,
          task_id: task_id,
        }).then(
          (response) => {
            if (response.data.detail.response.status === "success") {
              this.stopInterval();
              clearInterval(this.intervalId);
              this.getCatalogItems();
            }
          },
          (error) => {
            this.stopInterval();
            clearInterval(this.intervalId);
            this.alertMessage = error.message;
            this.alertDialog = true;
          }
        );
      }, 1000);
    },
    stopInterval() {
      this.is_task_running = false;
      this.selectedDocuments = [];
      clearInterval(this.intervalId);
    },
    removeItems() {
      const itemIds = this.selectedDocuments.map(document => document.id);
      const isDeleteAll = this.selectedDocuments.length === 0;

      this.REMOVE_CATALOG_ITEMS({
        project_id: this.selectedProject.id,
        item_ids: isDeleteAll ? [] : itemIds,
        is_delete_all: isDeleteAll,
      }).then(
        (response) => {
          if (response.status === 202) {
            this.startInterval(response.data ?.detail.response);
            this.deleteDialog = false;
          }
        },
        (error) => {
          this.alertMessage = error.message;
          this.deleteDialog = false;
          this.alertDialog = true;
        }
      );
    },
    deleteItem(item) {
      this.selectedDocuments = [item];
      this.delete_alert_message = this.$vuetify.lang.t("$vuetify.confirm_alert_msg_txt_7");
      this.deleteDialog = true;
    },
    handleDeleteClick() {
      this.delete_alert_message = this.selectedDocuments.length ?
        this.$vuetify.lang.t("$vuetify.confirm_alert_msg_txt_9") :
        this.$vuetify.lang.t("$vuetify.confirm_alert_msg_txt_8");
      this.deleteDialog = true;
    },
    updateItem(item) {
      this.selectedItem = JSON.parse(JSON.stringify(item));
      this.openEditModal = true;
    },

    saveUpdatedItem() {
      this.isLoading = true;
      if (this.timeToNextToken() < 300) {
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then(
          (response) => {
            this.updateItemFunction();
          },
          (error) => {
            this.isLoading = false;
          }
        );
      } else {
        this.updateItemFunction();
      }
    },

    updateItemFunction() {
      if (this.selectedItem && this.selectedItem.item) {
        for (let key in this.selectedItem.item) {
          if (Array.isArray(this.selectedItem.item[key])) {
            this.selectedItem.item[key] = this.selectedItem.item[key].join(', ');
          }
        }
      }
      this.UPDATE_CATALOG_ITEM({
        project_id: this.selectedProject.id,
        item_id: this.selectedItem.id,
        payload: this.selectedItem
      })
        .then(() => {
          this.openEditModal = false;
          this.isLoading = false;
          this.$store.dispatch(`alert/${ADD_ALERT}`, {
            message: this.$vuetify.lang.t("$vuetify.catalog.catalog_item_success_msg_txt"),
            color: "success",
          }, {
            root: true
          });
          setTimeout(() => {

            this.getCatalogItems();

          }, 1000);

        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    updateSort() {
      this.getCatalogItems();

    },
    setDates() {
      this.$refs.menuDates.save(this.dates);
      if (this.dates.length === 1) {
        this.dates[1] = dateOfToday();
        this.end_date = dateOfToday();
      }
      const start_date = this.dates[0];
      const end_date = this.dates[1];
      this.resetTable();

      this.fetchCatalogItems();
    },
    resetTable() {
      this.page = 1;
      this.searchText = '';

    },
    fetchCatalogItems() {
      this.isLoading = true;

      if (timeToNextToken() < 300) {
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then(
          (response) => {
            this.page=1;
            this.getCatalogItems();
          },
          (error) => error
        );
      } else {
        this.page=1;
        this.getCatalogItems();
      }
    },
    getCatalogItems() {
      let project_id = this.selectedProject.id;
      const start = (this.page - 1) * this.itemsPerPage;
      const size = this.itemsPerPage;
      if (this.dates.length === 0) {
        let today = new Date();
        const formattedDate = today.toISOString().split('T')[0].replace(/-/g, '-');
        this.dates[0] = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0].replace(/-/g, '-');
        this.dates[1] = formattedDate;
        this.end_date = formattedDate;
      } else if (this.dates.length === 1) {
        let today = new Date();
        const formattedDate = today.toISOString().split('T')[0].replace(/-/g, '-');
        this.dates[1] = formattedDate;
        this.end_date = formattedDate;
      }
      let start_date = this.dates[0] || null;
      let end_date = this.dates[1] || null;
      const priceKey = this.mapper.price;
      const payload = {
        start_date: start_date,
        end_date: end_date,
        text: this.searchText || null,
        sort: {
          [priceKey]: this.sortDesc ? 'desc' : 'asc'
        },
        ranges: {
          [priceKey]: {
            gte: this.priceRange[0] || undefined,
            lte: this.priceRange[1] || undefined,
          },
        },
        start: start,
        size: size
      };
      this.GET_CATALOG_ITEMS({
        project_id: project_id,
        payload: payload
      }).then(
        (response) => {
          this.catalogTableItems = response.data.detail.response.items;
          this.minPrice = Math.min(this.minPrice, response.data.detail.response.min_price);
          this.maxPrice = Math.max(this.maxPrice, response.data.detail.response.max_price);
          this.priceRange[0] = Math.max(this.priceRange[0], this.minPrice);
          this.priceRange[1] = Math.min(this.priceRange[1], this.maxPrice);
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    formatKey(key) {
      return key.replace(/_/g, ' ').replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    },
    updateCatalog() {
      this.getCatalogItems();
    },
    onPriceRangeInput() {
      this.priceRange = [this.minPrice, this.maxPrice];
      this.updateCatalog();
    },
  },
};
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
