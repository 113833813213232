<template>
  <div>
    <Loader
      v-if="isLoading"
      class="mr-2 mt-6"
      loaderType="card-avatar, article, actions"
    />
    <v-container fill-height fluid v-else class="ma-0 pa-0">
      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab" show-arrows>
            <v-tabs-slider></v-tabs-slider>
            <template v-if="isDeveloper() && !isAdmin()">
              <v-tab class="tab-option" key="all" href="#all">
                {{ $vuetify.lang.t("$vuetify.solutions_users_tab") }}
                <v-icon v-if="icons">mdi-phone</v-icon>
              </v-tab>
              <v-tab class="tab-option" key="projects" href="#projects" v-show="isAdmin()" >
                {{ $vuetify.lang.t("$vuetify.solutions_projects_tab") }}
                <v-icon v-if="icons">mdi-phone</v-icon>
              </v-tab>
              <v-tab class="tab-option" key="mysolutions" href="#mysolutions" v-show="isDeveloper()">
                {{ $vuetify.lang.t("$vuetify.solutions_developers_tab") }}
                <v-icon v-if="icons">mdi-phone</v-icon>
              </v-tab>
            </template>
            <template v-else>
              <v-tab class="tab-option" key="projects" href="#projects" v-show="isAdmin()  ">
                {{ $vuetify.lang.t("$vuetify.solutions_projects_tab") }}
                <v-icon v-if="icons">mdi-phone</v-icon>
              </v-tab>
              <v-tab class="tab-option" key="all" href="#all">
                {{ $vuetify.lang.t("$vuetify.solutions_users_tab") }}
                <v-icon v-if="icons">mdi-phone</v-icon>
              </v-tab>
              <v-tab class="tab-option" key="mysolutions" href="#mysolutions" v-show="isDeveloper()">
                {{ $vuetify.lang.t("$vuetify.solutions_developers_tab") }}
                <v-icon v-if="icons">mdi-phone</v-icon>
              </v-tab>
            </template>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row  v-if="this.tab !== 'projects'">
        <v-col cols="12" md="12" v-if="this.tab !== 'projects'">
          <v-text-field
            v-model="searchText"
            outlined
            :label="$vuetify.lang.t('$vuetify.search_label_txt')"
            prepend-inner-icon="search"
            @keypress.enter="search"
          >
            <template v-slot:append>
              <v-btn
                large
                class="text-capitalize font-weight-bold mt-n3 mr-n2"
                color="primary"
                append-icon="search"
                @click="search"
              >
                <span class="d-none d-sm-flex">{{ $vuetify.lang.t("$vuetify.search_btn_txt") }}</span>
                <v-icon class="d-flex d-sm-none">search</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="pt-3" v-else></v-row>
      <v-row class="mt-n9">
        <v-col cols="12" xs="12" md="12">
          <v-tabs
            v-model="tab"
            :centered="centered"
            :grow="grow"
            :vertical="vertical"
            :right="right"
            :prev-icon="
              prevIcon ? 'mdi-arrow-left-bold-box-outline' : undefined
            "
            :next-icon="
              nextIcon ? 'mdi-arrow-right-bold-box-outline' : undefined
            "
            :icons-and-text="icons"
          >
            <v-tab-item key="all" value="all">
              <v-row v-if="allSolution && allSolution.length !== 0">
                <v-col
                  cols="12"
                  xs="12"
                  sm="6"
                  md="6"
                  lg="4"
                  xl="4"
                  v-for="item in allSolution"
                  :key="item.id"
                >
                  <SolutionCard :itemData="item"/>
                </v-col>
              </v-row>
              <v-row v-else class="justify-center align-center mt-16">
                <h1>Sorry, No result found :(</h1>
              </v-row>
            </v-tab-item>
            <v-tab-item key="mysolutions" value="mysolutions" v-if="isDeveloper()">
              <DeveloperSolution :isDevSolLoading="isDevSolLoading" />
            </v-tab-item>
            <v-tab-item key="projects" value="projects">
              <ProjectLists :solution_type="$route.query.type"/>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
      <!-- <v-row v-else>
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          v-for="item in allSolution"
          :key="item.id"
        >
          <SolutionCard :itemData="item" />
        </v-col>
      </v-row> -->
    </v-container>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import {
  LOAD_ALL_SOLUTION,
  REFRESHTOKEN,
  LOAD_ALL_SOLUTION_BY_DEV
} from "@/store/_actiontypes";
import Loader from "@/components/common/loader/Loader";
import SolutionCard from "@/components/Solutions/SolutionCard";
import DeveloperSolution from "@/components/Solutions/DeveloperSolution";
import ProjectLists from "@/components/Projects/ProjectLists"
import { timeToNextToken, authorize } from "@/helpers/helper";
import { Role } from "@/helpers/role";
import jwt_decode from "jwt-decode";
import getEnv from "@/config/env";
import {
  SEGMENTS
} from '@/global/_constants';
export default {
  props: {
    role: String,
  },
  components: {
    Loader,
    SolutionCard,
    DeveloperSolution,
    ProjectLists
  },
  computed: {
    ...mapState({
      allSolution: (state) => state.solution.allSolution,
      user: (state) => state.account.user,
    }),
    ...mapGetters("loader", ["loading"]),
    size() {
      const size = { xs: "x-small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
  },
  // mounted() {
  //   this.getAllSolutions("",this.type);
  // },
  data() {
    return {
      isLoading: false,
      isDevSolLoading: false,
      searchText: "",
      tab: null,
      icons: false,
      centered: false,
      grow: false,
      vertical: false,
      prevIcon: false,
      nextIcon: false,
      right: false,
      PROJECT_SEGMENTS: SEGMENTS,
      tabs: 2,
      type: "Personalizer",
      isFirstRenderAfterReload: true,
      Role,
      authorize,
    };
  },
  created() {
    this.type = this.$route.query.type;
    if ("dev" === this.role){
      this.tab = "mysolutions";
    }
  },
  watch: {
    "tab": {
      handler: function() {
        if (this.tab && this.tab !== 'projects') {
          this.tab === 'mysolutions' ? this.getDevSolutions('', this.type) : this.getAllSolutions('',this.type);
        }
      },
      deep: true
    },
    "$route.query.type": {
      handler: function (newType) {
        if (!this.isFirstRenderAfterReload) {
          this.type = newType;
          if (this.tab && this.tab === 'mysolutions') {
            this.getDevSolutions('', newType);
          } else if ( this.tab && this.tab !== 'projects'){
            this.getAllSolutions('', this.type);
          }
        } else {
          this.isFirstRenderAfterReload = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions("solution", [ LOAD_ALL_SOLUTION, LOAD_ALL_SOLUTION_BY_DEV ]),
    ...mapActions("account", [REFRESHTOKEN]),
    isDeveloper() {
      let parsedJWT = jwt_decode(this.user.access_token);
      return parsedJWT["scope"]?.includes("developer");
    },
    isAdmin(){
      let parsedJWT = jwt_decode(this.user.access_token);
      return parsedJWT["scope"]?.includes("admin");
    },
    getAllSolutions(searchKey, type) {
      this.isLoading = true;
      if (timeToNextToken() < 300) {
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then(
          (response) => {
            this.fetchAllSolutions(searchKey, type);
          },
          (error) => error
        );
      } else {
        this.fetchAllSolutions(searchKey, type);
      }
    },
    fetchAllSolutions(searchKey, type) {
      this.LOAD_ALL_SOLUTION({ searchText: searchKey, type: type })
      .then(
        (response) => {
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    getDevSolutions(searchKey, type) {
      this.isDevSolLoading = true;
      if (timeToNextToken() < 300) {
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then(
          (response) => {
            this.fetchDevSolutions(searchKey, type);
          },
          (error) => error
        );
      } else {
        this.fetchDevSolutions(searchKey, type);
      }
    },
    fetchDevSolutions(searchKey, type) {
      this.LOAD_ALL_SOLUTION_BY_DEV({ searchText: searchKey, type: type })
      .then((response) => {
        this.isDevSolLoading = false;
      })
      .catch((error) => {
        this.isDevSolLoading = false;
      });
    },
    search() {
      if (this.tab) {
        this.tab === "all" ? this.getAllSolutions(this.searchText, this.type) : this.getDevSolutions(this.searchText, this.type);
      } else {
        this.getAllSolutions(this.searchText, this.type);
      }
    },
  },
};
</script>

<style scoped>
</style>
