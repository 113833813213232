<template>
    <v-card outlined class="mx-auto px-2">
        <template>
            <v-row>
                <v-col xs="6" md="6" sm="6">
                    <div class="px-2 primary--text font-weight-bold">
                        {{ $vuetify.lang.t("$vuetify.logs.questionnaire_logs_title") }}
                    </div>
                </v-col>
                <v-col md="6">
                    <v-btn class="float-right" color="primary" dark @click="downloadCSV" large>
                        <span class="v-btn__content">
                            <i aria-hidden="true" class="v-icon notranslate mr-1 mdi mdi-download theme--light"></i>
                            {{ $vuetify.lang.t("$vuetify.common.download") }}
                        </span>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="d-flex flex-column flex-md-row pa-4 mb-n6">
                <v-col width class="pa-0 pr-0 pr-md-3">
                    <v-text-field v-model="questionnaireFilter"
                        :label="$vuetify.lang.t('$vuetify.filter.questionnaire_id')" outlined dense
                        clearable></v-text-field>
                </v-col>
                <v-col width class="pa-0 pr-0 pr-md-3">
                    <v-text-field v-model="userFilter" :label="$vuetify.lang.t('$vuetify.filter.user_id')" outlined
                        dense clearable></v-text-field>
                </v-col>
                <v-col class="pa-0 pr-0 pr-md-3">
                    <v-text-field v-model="searchFilter" :label="$vuetify.lang.t('$vuetify.filter.search_query')"
                        outlined dense clearable></v-text-field>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-data-table :headers="questionnairesLogsHeaders" :items="questionnaires_logs"
            :loading="isLoading"
            loading-text="Loading... Please wait"
            :server-items-length="totalHits"
            :items-per-page="itemsPerPage"
            :page="page"
            @update:page="page = $event"
            @update:items-per-page="itemsPerPage = $event"
            :footer-props="{
                showFirstLastPage: true,
            }"
            
            >
                <template v-slot:item.questionnaire_id="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{
                                item.questionnaire_id ? item.questionnaire_id.split("-")[0] : ''
                            }}</span>
                        </template>
                        <span>{{ item.questionnaire_id || 'N/A' }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.user_id="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{
                                item.user_id ? item.user_id.split("-")[0] : ''
                             }}</span>
                        </template>
                        <span>{{ item.user_id || 'N/A' }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.member_id="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{
                                item.member_id ? item.member_id.split("-")[0] : ''
                            }}</span>
                        </template>
                        <span>{{ item.member_id || 'N/A' }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.request_id="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{
                                item.request_id ? item.request_id.split("-")[0] : ''
                            }}</span>
                        </template>
                        <span>{{ item.request_id || 'N/A' }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.created_at="{ item }">
                    {{ formatDate(item.created_at) }}
                </template>
                <template v-slot:item.prompt="{ item }">
                    {{ item.prompt? 
                        item.prompt.length > 50 ? item.prompt.substring(0, 50) + "..." : item.prompt
                        : "N/A" }}
                </template>
                <template v-slot:item.response="{ item }">
                    <div class="d-flex justify-center">
                        <v-icon v-bind="attrs" v-on="on" class="mr-1"
                            @click="detail_response_modal(item)">mdi-eye</v-icon>
                    </div>
                </template>
            </v-data-table>
            <template v-if="detailModal">
                <v-dialog v-model="detailModal">
                    <v-card>
                        <v-card-title>
                            <span class="headline">
                                {{ $vuetify.lang.t("$vuetify.questionnaire.questionnaire_response_title") }}
                            </span>
                        </v-card-title>
                        <v-card-text class="">
                            <h2 class="font-weight-black mb-4">{{ $vuetify.lang.t("$vuetify.questionnaire.survey_values") }}</h2>
                            <v-row>
                                <v-col cols="12"
                                v-if="!Object.keys(detailResponseModalData.survey_values || {}).length"
                                >
                                    <span>N/A</span>
                                    </v-col>
                                <v-col v-else v-for="(
                  value, key
                ) in detailResponseModalData.survey_values || {}" :key="key" cols="12">
                                    <v-expansion-panels>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>{{
            key
        }}</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col v-for="(val, subkey) in value" :key="subkey" cols="12">
                                                        <v-row>
                                                            <v-col cols="6">
                                                                <span>{{ subkey }}</span>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <span>
                                                                    :
                                                                    {{
            Array.isArray(val)
                ? val.join(", ") || "N/A"
                : typeof val === "object" && val !== null
                    ? Object.values(val).join(", ")
                    : val || "N/A"
        }}
                                                                </span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                            </v-row>

                            <h2 class="font-weight-black my-4">{{ $vuetify.lang.t("$vuetify.questionnaire.prompt") }}</h2>
                            <p>
                                {{ detailResponseModalData.prompt || "N/A" }}
                            </p>

                            <h2 class="font-weight-black my-4">{{ $vuetify.lang.t("$vuetify.questionnaire.recommendation") }}</h2>
                            <v-row>
                                <v-col cols="12" v-if="!Object.keys(detailResponseModalData.response.detail.recommendations || {}).length">
                                    <span>N/A</span>
                                </v-col>
                                <v-col v-else v-for="(value, key) in detailResponseModalData.response
            .detail.recommendations || {}" :key="key" cols="12">
                                    <v-expansion-panels>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="font-weight-black">{{
            key
        }}</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <table>
                                                        <tr
                                                        v-if="!Object.keys(value || {}).length"
                                                        >
                                                            <td>N/A</td>
                                                        </tr>
                                                        <tr v-else v-for="(item, index) in value" :key="index">
                                                            <td class="item_btn" @click="openItemDetails(item)">
                                                                <span> {{ Object.values(item)[0] }}</span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                            </v-row>
                            <template v-if="itemDetailsModal">
                                <v-dialog v-model="itemDetailsModal">
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">
                                                {{ $vuetify.lang.t("$vuetify.insights.item") }} {{ $vuetify.lang.t("$vuetify.common.details") }}
                                            </span>
                                        </v-card-title>
                                        <v-card-text>
                                            <template v-if="itemDetailsData">

                                                <v-row v-for="(value, key) in itemDetailsData" :key="key">
                                                    <v-col cols="3">
                                                        <span>{{ key }}</span>
                                                    </v-col>
                                                    <v-col cols="9">
                                                        <span>
                                                            :
                                                            {{ Array.isArray(value) ? value.join(", ") || "N/A" : typeof
                                                            value === "object" && value !== null ?
                                                            Object.values(value).join(", ") : value || "N/A" }}
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-card-text>
                                        <v-card-actions class="d-flex justify-end">
                                            <v-btn color="error" text @click="itemDetailsModal = false"> {{ $vuetify.lang.t("$vuetify.close_btn_txt") }}</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </template>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end">
                            <v-btn color="error" text @click="detailModal = false"> {{ $vuetify.lang.t("$vuetify.close_btn_txt") }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
        </template>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { REFRESHTOKEN, GET_QUESTIONNAIRE_LOG } from "@/store/_actiontypes";
import Loader from "@/components/common/loader/Loader";
import { timeToNextToken } from "@/helpers/helper";

export default {
    props: {
        startDate: {
            type: String,
            required: false,
        },
        endDate: {
            type: String,
            required: false,
        },
    },

    components: {
        Loader,
    },

    computed: {
        ...mapState({
            user: (state) => state.account.user,
            selectedProject: (state) => state.project.selectedProject,
        }),
        ...mapGetters("loader", ["loading"]),
    },

    watch: {
        startDate(newVal) {
            this.page = 1;
            this.getQuestionnaire();
        },
        endDate(newVal) {
            this.page = 1;
            this.getQuestionnaire();
        },
        page(newVal) {
            this.getQuestionnaire();
        },
        itemsPerPage(newVal) {
            this.page = 1;
            this.getQuestionnaire();
        },
        questionnaireFilter(newVal) {
            this.page = 1;
            this.getQuestionnaire();
        },
        userFilter(newVal) {
            this.page = 1;
            this.getQuestionnaire();
        },
        searchFilter(newVal) {
            this.page = 1;
            this.getQuestionnaire();
        },
    },

    mounted() {
        this.getQuestionnaire();
    },

    data() {
        return {
            isLoading: false,
            questionnaires_logs: [],
            questionnaireFilter: "",
            userFilter: "",
            searchFilter: "",
            page: 1,
            itemsPerPage: 10,
            totalHits: 0,
            returnedHits: 0,
            questionnairesLogsHeaders: [
                {
                    text: this.$vuetify.lang.t("$vuetify.questionnaire.questionnaire_id"),
                    align: "start",
                    sortable: false,
                    value: "questionnaire_id",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.questionnaire.user_id"),
                    align: "start",
                    sortable: false,
                    value: "user_id",
                },
                {
                    text: "Member ID",
                    align: "start",
                    sortable: false,
                    value: "member_id",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.questionnaire.request_id"),
                    align: "start",
                    sortable: false,
                    value: "request_id",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.questionnaire.created_at"),
                    align: "start",
                    sortable: false,
                    value: "created_at",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.questionnaire.prompt"),
                    align: "start",
                    sortable: false,
                    value: "prompt",
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.questionnaire.response"),
                    align: "start",
                    sortable: false,
                    value: "response",
                },
            ],
            detailModal: false,
            detailResponseModalData: {},
            itemDetailsModal: false,
            itemDetailsData: {},
        };
    },

    methods: {
        ...mapActions("questionnaire", [GET_QUESTIONNAIRE_LOG]),
        ...mapActions("account", [REFRESHTOKEN]),
        getQuestionnaire() {
            if (timeToNextToken() < 300) {
                this.REFRESHTOKEN({
                    refresh_token: this.user.refresh_token,
                }).then(
                    (response) => {
                        this.getQuestionnaireFunction();
                    },
                    (error) => { }
                );
            } else {
                this.getQuestionnaireFunction();
            }
        },
        getQuestionnaireFunction() {
            let params = {
                start: (this.page - 1) * this.itemsPerPage,
                size: this.itemsPerPage,
            };
            if (this.questionnaireFilter) {
                params.questionnaire_id = this.questionnaireFilter;
            }
            if (this.userFilter) {
                params.user_id = this.userFilter;
            }
            if (this.searchFilter) {
                params.search = this.searchFilter;
            }
            if (this.startDate) {
                params.start_date = this.startDate;
            }
            if (this.endDate) {
                params.end_date = this.endDate;
            }
            this.isLoading = true;
            this.GET_QUESTIONNAIRE_LOG({
                project_id: this.selectedProject.id,
                params,
            }).then(
                (response) => {
                    this.questionnaires_logs = response.data.logs.recommendation_log;
                    this.totalHits = response.data.logs.total_hits;
                    this.returnedHits = response.data.logs.returned_hits;
                },
                (error) => {
                    console.log("error", error);
                }
            ).finally(() => {
                this.isLoading = false;
            });
        },
        downloadCSV() {
            const headers = this.questionnairesLogsHeaders.map(
                (header) => header.text
            );
            headers.pop();
            const rows = this.questionnaires_logs.map((log) => [
                log.questionnaire_id,
                log.user_id || "N/A",
                log.member_id || "N/A",
                log.request_id || "N/A",
                this.formatDate(log.created_at).replace(",", " "),
                log.prompt ? log.prompt.replace(",", " ") : "N/A",
            ]);
            let csvContent =
                "data:text/csv;charset=utf-8," +
                [headers.join(","), ...rows.map((e) => e.join(","))].join("\n");

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "Questionnaires Logs.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        formatDate(date) {
            return new Date(date).toLocaleString();
        },
        detail_response_modal(item) {
            this.detailResponseModalData = item;
            this.detailModal = true;
        },
        openItemDetails(item) {
            this.itemDetailsData = item;
            this.itemDetailsModal = true;
        },
    },
};
</script>

<style scoped>
.item_btn {
    cursor: pointer;
    font-weight: bold;
    color: #1f5592;
    padding: 5px;
}
</style>
