<template>
  <div>
    <v-card class="elevation-0">
      <v-card-title class="font-weight-bold">
        {{ $vuetify.lang.t("$vuetify.profile_basic_tab") }}
      </v-card-title>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.project_name_header_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ selectedProject.name }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.language_header_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ selectedProject.language ? selectedProject.language : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.solution_header_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            <v-card flat>
              <v-chip
                v-if="selectedProject.segment"
                class="ma-0"
                v-bind:color="selectedProject.segment.color"
                label
                outlined
                pill
              >
                {{ capitalizeFirstLetter(selectedProject.segment.name) }}
              </v-chip>
            </v-card>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.industry_header_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ selectedProject.industry ? selectedProject.industry.name : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.created_at_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{
              selectedProject.created_at
                ? formatDateTime(selectedProject.created_at)
                : "N/A"
            }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <v-card class="elevation-0">
      <v-card-title class="font-weight-bold">
        {{ `${$vuetify.lang.t("$vuetify.enabled_solution_label_txt")}` }}
      </v-card-title>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-content class="font-weight-bold align-self-start">
            {{ `${$vuetify.lang.t("$vuetify.enabled_solution_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            <div
              v-for="(item, index) in selectedProject.solutions"
              :key="index"
              class="py-2"
            >
              <v-icon v-bind:color="selectedProject.segment.color" size="21" class="mr-1">
                task_alt
              </v-icon>
              {{ item.name }}
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <v-card class="elevation-0">
      <v-card-title class="font-weight-bold">
        {{ `${$vuetify.lang.t("$vuetify.project_app_infra_step_txt")}:` }}
      </v-card-title>
      <v-divider></v-divider>
      <v-list>
        <!-- <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.expected_request_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ formatNum(selectedProject.monthly_pv) }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.request_per_sec_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{
              selectedProject.expected_rps
                ? formatNum(selectedProject.expected_rps)
                : "N/A"
            }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.unique_user_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ formatNum(selectedProject.monthly_uu) }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.item_count_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ formatNum(selectedProject.item_count) }}
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.cloud_provider_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            Amazon Web Services
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{
              `${$vuetify.lang.t("$vuetify.cloud_provider_label_txt")} ${$vuetify.lang.t(
                "$vuetify.region_label_txt"
              )}:`
            }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{
              selectedProject.region
                ? `${selectedProject.region.timezone} (${selectedProject.region.cloud_provider_region})`
                : "N/A"
            }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.auto_scaling_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ selectedProject.auto_scaling ? "Enabled" : "Disabled" }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <v-card v-if="selectedProject.subscription_type" class="elevation-0">
      <v-card-title class="font-weight-bold">
        {{ `${$vuetify.lang.t("$vuetify.subs_label_txt")}` }}
      </v-card-title>
      <v-divider></v-divider>
      <v-list>
         <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.subs_type_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            <v-card flat>
              <v-chip
                class="ma-0"
                v-bind:color="getSubscriptionTypeColor(selectedProject.subscription_type)"
                label
                outlined
                pill
              >
                {{ selectedProject.subscription_type ==="trial" ? capitalizeFirstLetter(selectedProject.subscription_type) : "Standard" }}
              </v-chip>
            </v-card>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="selectedProject.subscription_type==='paid'">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.billing_cycle_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{
              selectedProject.current_billing_cycle
                ? formatDateTime(selectedProject.current_billing_cycle.start_date)+" ~ "+formatDateTime(selectedProject.current_billing_cycle.end_date)
                : "N/A"
            }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="selectedProject.subscription_type==='paid'">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.est_total_cost_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ formatNum(selectedProject.billing_amount) }}
            {{ selectedProject.currency ? selectedProject.currency : "USD" }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="selectedProject.subscription_type==='trial'" >
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.trial_subs_expire_date_label")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{
              formatDateTime(selectedProject.trial_end_date)
            }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="selectedProject.subscription_type==='paid'">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.next_invoice_issue_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{
              selectedProject.next_invoice_date
                ? formatDateTime(selectedProject.next_invoice_date , "","True")
                : "N/A"
            }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="selectedProject.subscription_type==='paid'">
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.next_invoice_due_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{
              selectedProject.next_invoice_due_date
                ? formatDateTime(selectedProject.next_invoice_due_date,"", "True")
                : "N/A"
            }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="selectedProject.segment && selectedProject.segment.name !== PROJECT_SEGMENTS.Maira" >
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.item_limit_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ selectedProject.subscription_type==="trial" ? formatNum(10000) : formatNum(50000)}}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.subs_api_rate_limit")}:` }}
          </v-list-item-content>
          <v-list-item-content v-if="selectedProject.segment && selectedProject.segment.name !=PROJECT_SEGMENTS.Maira" class="align-end">
            <ul>
              <li>{{$vuetify.lang.t("$vuetify.ai_assistant_txt")}}: {{selectedProject.gpt_ask_calls_per_period}} {{$vuetify.lang.t("$vuetify.calls_txt")}}/900 {{$vuetify.lang.t("$vuetify.seconds_txt")}}</li>
              <li>{{$vuetify.lang.t("$vuetify.search_txt")}}: {{selectedProject.search_calls_per_period }} {{$vuetify.lang.t("$vuetify.calls_txt")}}/900 {{$vuetify.lang.t("$vuetify.seconds_txt")}}</li>
              <li>{{$vuetify.lang.t("$vuetify.recommendation_txt")}}: {{selectedProject.recommend_calls_per_period }} {{$vuetify.lang.t("$vuetify.calls_txt")}}/900 {{$vuetify.lang.t("$vuetify.seconds_txt")}}</li>
            </ul>
          </v-list-item-content>
          <v-list-item-content v-if="selectedProject.segment && selectedProject.segment.name ===PROJECT_SEGMENTS.Maira" class="align-end">
            {{$vuetify.lang.t("$vuetify.ai_assistant_txt")}}: {{selectedProject.gpt_ask_calls_per_period}} {{$vuetify.lang.t("$vuetify.calls_txt")}}/900 {{$vuetify.lang.t("$vuetify.seconds_txt")}}
          </v-list-item-content>
        </v-list-item>

       <!-- <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.next_billing_date_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            add next billing date
          </v-list-item-content>
        </v-list-item> -->

        <!-- <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.subs_cost_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ formatNum(selectedProject.subscription_fee) }}
            {{ selectedProject.currency ? selectedProject.currency : "USD" }}
          </v-list-item-content>
        </v-list-item> -->

        <!-- <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ `${$vuetify.lang.t("$vuetify.subs_start_label_txt")}:` }}
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{
              selectedProject.start_date
                ? formatDateTime(selectedProject.start_date)
                : "N/A"
            }}
          </v-list-item-content>
        </v-list-item> -->
        <br> <br>
        <v-list-item v-if="selectedProject.subscription_type==='paid'">
          {{ `${$vuetify.lang.t("$vuetify.customer_support_message")}` }}
        </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-btn
            class="text-capitalize font-weight-bold"
            color="primary"
            large
            v-if="selectedProject.subscription_type==='trial'" 
            @click="proceedUnlockClick()"
            :loading="isLoading"
            :disabled="isLoading"
          >
            {{ $vuetify.lang.t("$vuetify.subs_upgrade_btn_txt") }}
          <v-icon right class="ml-2"> unlocked </v-icon>
            <v-progress-circular
              v-if="isLoading"   
              indeterminate
              color="white" 
            ></v-progress-circular>
          </v-btn>
        </v-list-item-content>
      </v-list-item>
      </v-list>
      </v-card> 
      <v-dialog
        v-model="confirmDialog"
        width="60%"
      >
        <v-card>
          <v-card-title
              class="headline primary white--text"
              primary-title
          >
              {{$vuetify.lang.t("$vuetify.subs_upgrade_modal_title")}}
          </v-card-title>
          <v-layout
              class="px-5 py-5"
          >
              <v-row
                  class="mx-2 my-2"
              >
                  <span>
                    {{$vuetify.lang.t("$vuetify.subs_upgrade_modal_message")}}
                    <ol>
                      <li>{{$vuetify.lang.t("$vuetify.subscription_fee_header_txt")}}: $3,500/{{$vuetify.lang.t("$vuetify.month_txt")}}</li>
                      <li v-if=" selectedProject.segment && selectedProject.segment.name !=PROJECT_SEGMENTS.Maira">{{$vuetify.lang.t("$vuetify.item_limit_label_txt")}} : 50,000</li>
                      <li> {{$vuetify.lang.t("$vuetify.subs_api_rate_limit") }} : 
                          <ul>
                            <li>{{$vuetify.lang.t("$vuetify.ai_assistant_txt")}} : 600 {{$vuetify.lang.t("$vuetify.calls_txt")}}/900 {{$vuetify.lang.t("$vuetify.seconds_txt")}}</li>
                            <li v-if="selectedProject.segment && selectedProject.segment.name !=PROJECT_SEGMENTS.Maira">{{$vuetify.lang.t("$vuetify.search_txt")}}: 3600 {{$vuetify.lang.t("$vuetify.calls_txt")}}/900 {{$vuetify.lang.t("$vuetify.seconds_txt")}}</li>
                            <li v-if="selectedProject.segment &&selectedProject.segment.name !=PROJECT_SEGMENTS.Maira">{{$vuetify.lang.t("$vuetify.recommendation_txt")}}: 7200 {{$vuetify.lang.t("$vuetify.calls_txt")}}/900 {{$vuetify.lang.t("$vuetify.seconds_txt")}}</li>
                          </ul>
                      </li>
                    </ol>
                    <br>
                    {{$vuetify.lang.t("$vuetify.subs_upgrade_modal_message_continued")}}<br>
                    {{$vuetify.lang.t("$vuetify.subs_upgrade_modal_message_last")}}
                  </span>
              </v-row>
          </v-layout>
          <v-divider></v-divider>
          <v-card-actions>
              <v-spacer></v-spacer>
              <Button
                  :buttonType="TEXT_BUTTON"
                  v-bind:buttonText='$vuetify.lang.t("$vuetify.subs_upgrade_modal_cancel_btn_text")'
                  color="primary"
                  @onClick="confirmDialog=false"
              />
              <Button
                  :buttonType="RAISED_BUTTON"
                  v-bind:buttonText='$vuetify.lang.t("$vuetify.subs_upgrade_modal_confirm_btn_text")'
                  color="primary"
                  @onClick="proceedClick()"
              />
            </v-card-actions>
        </v-card>
    </v-dialog>
      <!-- <Modal
        v-model="confirmDialog"
        v-bind:title='$vuetify.lang.t("$vuetify.subs_upgrade_modal_title")'
        v-bind:message='$vuetify.lang.t("$vuetify.subs_upgrade_modal_message")'
        confirmButtonText="Confirm"
        width="60%"
        :cancelButton="true"
        @onCancel="confirmDialog=false"
        @onConfirmation="proceedClick()"
      /> -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import {
  SEND_UPGRADE_SUBSCRIPTION_REQUEST,
  UPGRADE_SUBSCRIPTION,
  REFRESHTOKEN,
  ADD_ALERT,
} from "@/store/_actiontypes";
import {
    TEXT_BUTTON,
    RAISED_BUTTON
} from '@/components/common/button/_buttontypes';
import {
  CONFIRMATION_MODAL
} from '@/components/common/modal/_modaltypes';
import {
  SEGMENTS
} from '@/global/_constants';
import Button from "@/components/common/button/Button";
import { formatNum, capitalizeFirstLetter, formatDateTime, timeToNextToken, getSubscriptionTypeColor } from "@/helpers/helper";
import Modal from "@/components/common/modal/Modal";
export default {
  props: {},
  components: {
    Modal,
    Button
  },
  data: () => ({
    getSubscriptionTypeColor,
    timeToNextToken,
    formatNum,
    capitalizeFirstLetter,
    formatDateTime,
    isLoading: false, 
    confirmDialog: false,
    CONFIRMATION_MODAL: CONFIRMATION_MODAL, 
    TEXT_BUTTON: TEXT_BUTTON,
    RAISED_BUTTON: RAISED_BUTTON,
    PROJECT_SEGMENTS: SEGMENTS
  }),
  computed: {
    ...mapState({
      selectedProject: (state) => state.project.selectedProject,
      user: (state) => state.account.user,
    }),
  },
  mounted() {},
  created() {},
  methods: {
    ...mapActions("alert", [ADD_ALERT]),
    ...mapActions("account", [REFRESHTOKEN]),
    ...mapActions("project", [UPGRADE_SUBSCRIPTION, SEND_UPGRADE_SUBSCRIPTION_REQUEST]),

    proceedUnlockClick(){
      this.confirmDialog = true;
    },

    proceedClick(){
      this.confirmDialog = false;
      console.log("Sending subscription upgrade request");
      this.sendUpgradeSubscriptionRequest();
    },

    sendUpgradeSubscriptionRequest() {
      if (this.timeToNextToken() < 300) {
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then(
          (response) => {
            this.sendUpgradeSubscriptionRequestFunction();
          },
          (error) => error
        );
      } else {
        this.sendUpgradeSubscriptionRequestFunction();
      }
    },
    sendUpgradeSubscriptionRequestFunction() {
      this.SEND_UPGRADE_SUBSCRIPTION_REQUEST({ 
        project_id: this.selectedProject.id,
      }).then(
        (response) => { 
          this.$store.dispatch(
            `alert/${ADD_ALERT}`,
            {
              message: this.$vuetify.lang.t("$vuetify.upgrade_project_request_success_msg_txt"),
              color: "success",
            },
            { root: true }
          );
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },

    upgradeSubscription() {
      //this.isLoading = true; 
      if (this.timeToNextToken() < 300) {
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then(
          (response) => {
            this.upgradeSubscriptionFunction();
          },
          (error) => error
        );
      } else {
        this.upgradeSubscriptionFunction();
      }
    },
    upgradeSubscriptionFunction() {
      this.UPGRADE_SUBSCRIPTION({ 
        project_id: this.selectedProject.id,
      }).then(
        (response) => { 
          this.selectedProject.subscription_type = response.data.subscription_type
          this.selectedProject.infra_fee = response.data.infra_fee
          this.selectedProject.subscription_fee = response.data.subscription_fee
          this.selectedProject.billing_amount = response.data.billing_amount

          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
  },
};
</script>
