import PersonalizerApi from "@/services/personalizer_api";
import {
    GET_TASK_STATUS
} from "@/store/_actiontypes";
import {

} from "@/store/_mutationtypes";
import { updateLoaderTo } from "./account";


const state = {

};

const actions = {
    [GET_TASK_STATUS]({ commit }, { project_id, task_id }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
            PersonalizerApi.get(`tasks/${task_id}`,
                {
                    headers: {
                        "project-id": project_id,
                    },
                }
            ).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
};

const mutations = {

};

export const task = {
    namespaced: true,
    state,
    actions,
    mutations,
};
