import store from "@/store";
import { REFRESHTOKEN } from "@/store/_actiontypes";
import { getCurrentUser } from "@/helpers/helper";

let refreshInterval = null;

const startTokenRefreshService = async () => {
    stopTokenRefreshService(); // Ensure no existing timer runs

    const checkAndRefreshToken = async () => {
        let user = getCurrentUser();
        if (user && user.expires_at) {
            const tokenExpiryTime = new Date(user.expires_at).getTime();
            const currentTime = new Date().getTime();
            const timeUntilExpiry = tokenExpiryTime - currentTime;

            // Refresh token 5 minutes before it expires
            const refreshThreshold = 5 * 60 * 1000;

            if (timeUntilExpiry < refreshThreshold) {
                try {
                    await store.dispatch(`account/${REFRESHTOKEN}`, { refresh_token: user.refresh_token }, { root: true });
                } catch (error) {
                    console.error("Token refresh failed:", error);
                }
            }
        }
    };

    // Start the check immediately and keep a reference to the initial await
    const initialCheck = checkAndRefreshToken();

    // Run the check every 2 minutes asynchronously
    refreshInterval = setInterval(checkAndRefreshToken, 2 * 60 * 1000);

    // Return the initial check promise to ensure it can be awaited
    return initialCheck;
};

const stopTokenRefreshService = () => {
    if (refreshInterval) {
        clearInterval(refreshInterval);
        refreshInterval = null;
    }
};

export { startTokenRefreshService, stopTokenRefreshService };
